

export const getItem = async key => {
  const data = await localStorage.getItem(key)
  const result = JSON.parse(data)
  return result
}

export const setItem = async (key, data) => {
  console.log('INSIDE SET ITEM', data)
  return await localStorage.setItem(key, JSON.stringify(data))
}

export const removeItem = key => {
  return localStorage.removeItem(key)
}

export const clearStorage = () => {
  return localStorage.clear()
};