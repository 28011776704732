import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import DataManagement from "./Pages/DataManagement";
import SegmentDetails from "./Pages/DataManagement/SegmentDetails";
import SitemapDetails from "./Pages/DataManagement/SitmapDetails";
import UserManagement from "./Pages/UserManagement";
import UserDetails from "./Pages/UserManagement/UserDetails";
import RoleManagement from "./Pages/RoleManagement";
import RoleDetails from "./Pages/RoleManagement/RoleDetails";
import Profile from "./Pages/Profile";
import ForgotPassword from "./Pages/ForgotPassword";
import SetPassword from "./Pages/ForgotPassword/SetPassword";
import ChangeLog from "./Pages/ChageLog";
import AdminRoute from "./AdminRoute";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoutes";
import ProfileRoute from "./ProfileRoute";
import NotFound from "./Pages/404";
import Expired from "./Pages/Expired";

const root = ReactDOM.createRoot(document.getElementById("root"));
document
  .getElementById("root")
  .setAttribute("style", "width:100%; height:100%");

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/Expired" element={<Expired />}></Route>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<Login />}></Route>
        <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
        <Route path="/ResetPassword" element={<SetPassword />}></Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Dashboard />}></Route>
      </Route>

      <Route element={<ProfileRoute />}>
        <Route path="/ChangeLog" element={<ChangeLog />}></Route>
        <Route path="/Profile" element={<Profile />}></Route>
      </Route>
      <Route element={<AdminRoute />}>
        <Route path="/datamanagement" element={<DataManagement />}></Route>
        <Route
          path="/datamanagement/SegmentDetails"
          element={<SegmentDetails />}
        ></Route>
        <Route
          path="/datamanagement/SitemapDetails"
          element={<SitemapDetails />}
        ></Route>
        <Route path="/rolemanagement" element={<RoleManagement />}></Route>
        <Route
          path="/rolemanagement/RoleDetails"
          element={<RoleDetails />}
        ></Route>
        <Route path="/usermanagement" element={<UserManagement />}></Route>
        <Route
          path="/usermanagement/userdetails"
          element={<UserDetails />}
        ></Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
