import { useState, useEffect, useLayoutEffect } from 'react';
import styles from './index.module.css'
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone, LockOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Input, Form, notification } from 'antd';
import AOS from 'aos';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../Components/Button'
import { loginUser } from '../../services/auth.services';
import { getCompanySetting } from '../../services/auth.services'
import 'aos/dist/aos.css';

const Login = () => {
  const navigate = useNavigate()
  const [loading, setloading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [logo, setLogo] = useState()

  const onFinish = (values) => {
    setloading(true)
    loginUser({ ...values, url: window.location.origin }, () => { navigate('/dashboard') }, () => {
      setloading(false)
      api['error']({
        message: 'Error: Login Failed',
        description:
          'Invalid Credentials, Please check your username and password and retry!'
      });
    })
  };

  const loadLogo = async () => {
    const logoUrl = await getCompanySetting(window.location.origin)
    setLogo(logoUrl)
  }
  useLayoutEffect(() => {
    loadLogo()
  }, [])

  useEffect(() => { AOS.init() }, [])
  return (
    <div className={styles.container}>
      <div className={styles.cardContainer} data-aos="fade-right"
        data-aos-duration="2000">
        <div
          style={{
            width: "200px",
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            height: "100px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <p></p>
        </div>

        <Form
          name="normal_login"
          className={styles.formContainer}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input
              className={styles.formInputs}
              placeholder="Enter your username"
              prefix={<UserOutlined className="site-form-item-icon"
                style={{ marginRight: '10px' }} />
              }

            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input.Password
              placeholder="Enter your password"
              className={styles.formInputs}
              prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: '10px' }} />}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="text" className="login-form-button" onClick={() => { navigate('/forgotpassword') }}>
              Forgot your password? Click Here to reset your password
              <ArrowRightOutlined />
            </Button>
          </Form.Item>

          <Form.Item >
            <div className={styles.btnContainer}>
              {contextHolder}
              <CustomButton
                htmlType="submit"
                text="LOGin"
                customStyle={{ width: '100%' }}
                isLoading={loading}
              ></CustomButton>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login