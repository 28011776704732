import { useRef, useEffect, useState } from 'react';
import { Input, Avatar, Popover, Button, Spin, Skeleton } from 'antd';
import { SendOutlined, UserOutlined, InfoCircleOutlined, DislikeOutlined, LikeOutlined, LikeFilled, DislikeFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import { getChatById, sendChatMessage } from '../../services/chat.services';
import { addSegmentTrainingData, getUserSegments } from '../../services/segment.services';
import moment from 'moment';
import AOS from 'aos';
import logo from '../../Assets/domainIcon.png'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import styles from './index.module.css'
import ThumbsDownModal from '../../Components/ThumbsDownModal'
import 'aos/dist/aos.css';

const Dashboard = () => {
  const textInput = useRef();
  const scrollRef = useRef();
  const location = useLocation()
  const navigate = useNavigate()

  const [prompt, setPrompt] = useState("");
  const [chatId, setChatId] = useState()
  const [chat, setChat] = useState([]);
  const [loadingChat, setLoadingChat] = useState(false)
  const [waitingPrompt, setWaitingPrompt] = useState(false)
  const [userSegment, setUserSegments] = useState()
  const [dislike, setDislike] = useState(false)
  const [dislikedChat, setDislikedChat] = useState({ id: '', question: '' })

  const onChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmit = async () => {
    setWaitingPrompt(true)
    if (prompt.length > 0) {
      const ques = prompt;
      setChat([...chat, {
        "chatQuery": prompt,
        "chatResponse": "Waiting",

      }]);
      setPrompt("");
      await sendChatMessage(chatId, {
        "chatQuery": ques
      }, (onSucess), onError)
    }
  }

  const handleResubmit = async (reprompt) => {
    setWaitingPrompt(true)

    setChat([...chat, {
      "chatQuery": reprompt,
      "chatResponse": "Waiting",

    }]);
    await sendChatMessage(chatId, {
      "chatQuery": reprompt
    }, (onSucess), onError)
  }
  const onSucess = (message) => {
    setWaitingPrompt(false)
    setChat([...chat, message])
  }
  const onError = (ques, status) => {
    setWaitingPrompt(false)
    if (status == '401') {
      navigate('/Expired')
    }
    else {
      let temp = {
        chatQuery: ques.chatQuery, chatResponse: ""
      }
      setChat([...chat, temp]);
    }
  }

  const getChatData = async () => {
    const queryParams = new URLSearchParams(location.search);
    const chatId = queryParams.get("id");
    if (chatId) {
      setLoadingChat(true)
      const chatHistory = await getChatById(chatId, () => { setLoadingChat(false) }, () => { navigate('/Expired') })
      setChatId(chatHistory.chatId)
      setChat(chatHistory.chatQueries)
    }
    else {
      setChat([])
    }
  }

  const getSegments = async () => {
    const data = await getUserSegments()
    setUserSegments(data)
  }

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" })
  }

  const generateResponse = (response, id) => {
    if (response) {
      if (response === "Waiting") {
        return <Spin spinning={true}>
        </Spin>
      }
      const temp = JSON.parse(response)
      if (temp) {
        if (temp.response.length > 0) {
          const parsedJson = JSON.parse(response);
          const responseWithLineBreaks = parsedJson.response.replace(/\\n/g, '\n');
          const lines = responseWithLineBreaks.split('\n');
          return <p style={{ fontSize: '15px', padding: '10px 0px' }}>
            {lines.map(line => (
              <p>{line}<br /></p>
            ))}
          </p>
        }
      }
    }
    else {
      return (<div className={styles.regenerateDiv}>
        <p style={{ fontSize: '15px', paddingTop: '10px' }}>
          We ran into an error while trying to generate a response, Please Click on the button below to retry:
        </p>
        <Button style={{ color: 'green', borderColor: 'green' }} onClick={() => { handleResubmit(id) }}>Regenerate Response</Button>
      </div>)
    }
  }

  const formatDate = (createdOn) => {
    var stillUtc = moment.utc(createdOn).toDate();
    var local = moment(stillUtc).local().format('MMMM Do YYYY, h:mm:ss a');
    return local.toString()
  }

  const findSegmentName = (id) => {
    if (id) {
      const found = userSegment?.find((element) => element.companySegmentId == id);
      return found.companySegmentName + ' Segment'
    }
    else {
      return 'Genral Internet'
    }
  }

  const handleThumbsUp = async (id, question, answer, queryId) => {
    const body = [
      {
        "companySegmentId": id,
        "trainingDataTypeId": 2,
        "trainingQuery": question,
        "trainingData": JSON.parse(answer).response,
        'trainingReferenceId': queryId,
      }
    ]

    await addSegmentTrainingData(body, () => {
      Swal.fire({
        icon: 'error',
        text: 'We were ran into a problem while trying save your feedbaack for this response, Please try again later',
        showConfirmButton: false,
        timer: 2500
      })
    })
    await getChatData()

  }
  useEffect(() => {
    if (textInput.current) {
      textInput.current.focus();
    }
  }, [textInput.current]);

  useEffect(() => {
    AOS.init()
    getSegments()
  }, [])

  useEffect(() => {
    getChatData()
  }, [location])

  useEffect(() => {
    scrollToBottom()
  }, [chat]);

  const UserObj = JSON.parse(localStorage.getItem("user"));

  return (

    <>
      <ThumbsDownModal
        segmentsUser={userSegment}
        open={dislike}
        close={() => { setDislike(false) }}
        chatId={dislikedChat}
        loadData={() => { getChatData() }}
      />
      <div className={styles.container}>
        {chatId === undefined &&
          <p className={styles.selectChatID}>
            Click the "Add Chat" button in the top left to create a new chat, or open previous chats using the left-hand column.
          </p>
        }
        {(chatId && chat.length === 0 && !loadingChat) &&
          <p className={styles.selectChatID}>
            What do you want to know? Enter a prompt or question to begin your info journey, then watch the magic happen
          </p>}
        {loadingChat ? (
          <div className={styles.chatContainer}>
            <Skeleton loading={true} active avatar className={styles.ChatQuestionConatiner} />
            <Skeleton loading={true} active avatar className={styles.ChatQuestionConatiner} />
          </div>
        ) :

          <div className={styles.chatContainer}>
            {chat.map(question => (<>
              <div className={styles.ChatQuestionConatiner} >
                <Avatar src={UserObj.profilePictureUrl} icon={<UserOutlined />} style={{ backgroundColor: '#2c3e50' }} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, }}
                />
                <div className={styles.ChatQuestion}>
                  <p style={{ fontWeight: '600' }}>You</p>
                  <p style={{ fontSize: '15px', paddingTop: '10px' }}>
                    {question.chatQuery}</p>
                  <p style={{ marginLeft: 'auto' }}>{formatDate(question.createdOn)}</p>
                </div>
              </div>

              <div className={styles.ChatResponseConatiner} >
                <Avatar src={logo} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, }}
                />
                <div className={styles.ChatAnswer}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ fontWeight: '600' }}>DomainAI</p>
                    <div>
                      {question.companySegmentId &&
                        <Popover title={`If this response is from the valid segment, please click here to enhaance the engine for this segment`} >
                          <Button disabled={question.trainingFeedbackId == 2 || question.trainingFeedbackId == 3 ? true : false} type='text' onClick={() => { handleThumbsUp(question.companySegmentId, question.chatQuery, question.chatResponse, question.chatResponseId) }}>
                            {question.trainingFeedbackId == 2 ? <LikeFilled /> : <LikeOutlined />}
                          </Button>
                        </Popover>
                      }
                      <Popover title={`If this response is from on invalid segment, please click here to enhaance the engine for this segment`} >
                        <Button disabled={question.trainingFeedbackId == 3 || question.trainingFeedbackId == 2 ? true : false} type='text' onClick={() => {
                          setDislikedChat({ id: question.chatResponseId, question: question.chatQuery })
                          setDislike(true)
                        }}>
                          {question.trainingFeedbackId == 3 ? <DislikeFilled /> : <DislikeOutlined />}
                        </Button>
                      </Popover>
                    </div>
                  </div>
                  {generateResponse(question.chatResponse, question.chatQuery)}
                  <Popover title={`This result was generated using the ${findSegmentName(question.companySegmentId)}`} className={styles.infoPopOver}>
                    <InfoCircleOutlined />
                  </Popover>
                </div>

              </div>
            </>))}

            <p ref={scrollRef}></p>
          </div>
        }
        {chatId && <Input
          disabled={waitingPrompt}
          ref={textInput}
          value={prompt}
          className={styles.formInputs}
          onChange={onChange}
          onPressEnter={handleSubmit}
          suffix={<SendOutlined onClick={handleSubmit} className="site-form-item-icon" style={{ marginLeft: '10px' }} />}
        />
        }
      </div>
    </>
  )
}

export default Dashboard