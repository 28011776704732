import { useState, useEffect } from 'react';
import styles from './index.module.css'
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone, LockOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Input, Form } from 'antd';
import AOS from 'aos';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../Components/Button'
import { loginUser } from '../../services/auth.services';
import 'aos/dist/aos.css';
import logo from '../../Assets/tricensionlogo.png'
const Login = () => {
  const navigate = useNavigate()

  useEffect(() => { AOS.init() }, [])

  const onFinish = (values) => {
    localStorage.clear()
    navigate('/')
  };

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer} data-aos="fade-right"
        data-aos-duration="2000">
        <div
          style={{
            width: "200px",
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            height: "100px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <p></p>
        </div>

        <Form
          name="normal_login"
          className={styles.formContainer}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <p>
            Your session has expired <br /> Please click the button below to reauthenticate
          </p>
          <Form.Item >
            <div className={styles.btnContainer}>
              <CustomButton
                htmlType="submit"
                text="GO to LOGin"
                customStyle={{ width: '100%' }}
              ></CustomButton>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Login