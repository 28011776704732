import React, { useState, useEffect } from 'react';
import { Button, Breadcrumb, Tooltip, Card, List, Skeleton, Form, Input } from 'antd';
import { useNavigate, NavLink } from 'react-router-dom';
import { getAllSegments, createSegment } from '../../services/segment.services';
import { createNewSitemap } from '../../services/sitemap.servicces';
import { FileTextOutlined, FolderOutlined } from '@ant-design/icons';
import styles from './index.module.css'
import Swal from 'sweetalert2';

const DataManagement = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [segments, setSegments] = useState([])
  const [sitemaps, setSiteMaps] = useState([])
  const [addSegment, setAddSegment] = useState(false)
  const [loadingAddSegment, setLoadingAddSegment] = useState(false)
  const [addUrl, setAddUrl] = useState(false)
  const [loadingAddUrl, setLoadingAddUrl] = useState(false)

  const onSucessSegments = (segmentData) => {
    let segment = []
    let sitemap = []
    segmentData.map((item) => {
      if (item.companySegmentType === 1) {

        segment.push(item)
      }
      else {
        if (item.companySegmentType === 2) {
          sitemap.push(item)
        }
      }
    })
    setSegments(segment)
    setSiteMaps(sitemap)
    setLoading(false)
  }

  const onErrorSegments = (status) => {
    if (status == '401') {
      navigate('/Expired')
    }
    else {

    }
  }

  const getSegments = async () => {
    setLoading(true)
    await getAllSegments(onSucessSegments, onErrorSegments)

  }

  const onFinishAddSegment = async (values) => {
    setLoadingAddSegment(true)
    await createSegment(values, () => {
      Swal.fire({
        icon: 'success',
        text: 'New segment has been scuessfully created',
        showConfirmButton: false,
        timer: 2500
      })
      setLoadingAddSegment(false);
      getSegments()
      setAddSegment(false)
    }, (text) => {
      Swal.fire({
        icon: 'error',
        text: text,
        showConfirmButton: false,
        timer: 2500
      })
      setLoadingAddSegment(false);

    })

  };

  const onFinishAddUrl = async (values) => {
    setLoadingAddUrl(true)
    await createNewSitemap(values, () => {
      setLoadingAddUrl(false)
      setAddUrl(false)
      getSegments()
    })
  };

  useEffect(() => {
    getSegments()
  }, [])
  return (
    <>
      {/* <AddSegmentModal /> */}
      <Card bordered title="Data Mangement" className={styles.cardContainer}
        style={{ paddingBottom: '10px' }}
      >
        <Breadcrumb
          items={[
            {
              title: <NavLink to="/dashboard">
                <span>Home</span>
              </NavLink>,
            },
            {
              title: 'Data Management',
            },

          ]}
        />

      </Card>
      <div className={styles.container}>
        <Card
          className={styles.cardContainer}
          bordered
          title={`Segment(s)`}
          extra={[<Button
            onClick={() => { setAddSegment(true) }}
            key="sider-menuitem-delete"
            disabled={addSegment}
            style={{ marginRight: '20px' }}>
            Add Segment
          </Button>]}
        >
          {addSegment &&
            <Form
              name="normal_login"
              className={styles.formContainer}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishAddSegment}
            >
              <Form.Item
                name="segmentName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the name of your new segment!',
                  },
                ]}
              >
                <Input
                  disabled={loadingAddSegment}
                  className={styles.formInputs}
                  placeholder="Enter Segment Name"
                  prefix={<FolderOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>
              <Form.Item
                name="segmentDetails"
                rules={[
                  {
                    required: true,
                    message: 'Please input the description of your new segment in the form of keyword!',
                  },
                ]}
              >
                <Input
                  disabled={loadingAddSegment}
                  className={styles.formInputs}
                  placeholder="Enter Segment Description"
                  prefix={<FileTextOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>
              <Form.Item >
                <div className={styles.btnContainer}>
                  <Button disabled={loadingAddSegment} style={{ color: 'red', borderColor: 'red' }} onClick={() => { setAddSegment(false) }}>Cancel</Button>
                  <Button loading={loadingAddSegment} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                  >Confirm</Button>
                </div>
              </Form.Item>
            </Form>
          }
          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segments.filter((item) => item.isDefault !== true)}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Tooltip placement="leftBottom" title={'Click Here to view details and edit this segment'} >
                      <NavLink to={`/datamanagement/SegmentDetails?id=${item.companySegmentId}`} className={styles.navigatioinLink}>
                        View Details
                      </NavLink>
                    </Tooltip>
                  ]}
                >
                  <List.Item.Meta
                    title={<NavLink to={`/datamanagement/SegmentDetails?id=${item.companySegmentId}`}>{item.companySegmentName}</NavLink>}
                    description={item.details.replace('This context contains information about', '')}
                  />
                </List.Item>
              )}
            />}
        </Card>
        <Card
          className={styles.cardContainer}
          bordered
          title={`URL(s)/Sitemap(s)`}
          extra={[<Button
            onClick={() => { setAddUrl(true) }}
            disabled={addUrl}
            key="sider-menuitem-delete"
            style={{ marginRight: '20px' }}>
            Add URL
          </Button>]}

        >
          {addUrl &&
            <Form
              name="normal_login"
              className={styles.formContainer}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishAddUrl}
            >
              <Form.Item
                name="sitemapName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the name of your new url/sitemap!',
                  },
                ]}
              >
                <Input
                  disabled={loadingAddUrl}
                  className={styles.formInputs}
                  placeholder="Enter Sitemap/URL Name"
                  prefix={<FolderOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please input the description of your new url/sitemap!',
                  },
                ]}
              >
                <Input
                  disabled={loadingAddUrl}
                  className={styles.formInputs}
                  placeholder="Enter URL/Sitemap Description in the form of keyword"
                  prefix={<FileTextOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>
              <Form.Item
                name="sitemapUrl"
                rules={[
                  {
                    required: true,
                    message: 'Please input the web address of your new url/sitemap!',
                  },
                ]}
              >
                <Input
                  disabled={loadingAddUrl}
                  className={styles.formInputs}
                  placeholder="Enter URL/Sitemap web address"
                  prefix={<FileTextOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>
              <Form.Item >
                <div className={styles.btnContainer}>
                  <Button disabled={loadingAddUrl} style={{ color: 'red', borderColor: 'red' }} onClick={() => { setAddUrl(false) }}>Cancel</Button>
                  <Button loading={loadingAddUrl} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                  >Confirm</Button>
                </div>
              </Form.Item>
            </Form>
          }
          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={sitemaps}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Tooltip placement="leftBottom" title={'Click Here to view details and edit this URL/Sitemap'} >
                      <NavLink to={`/datamanagement/SitemapDetails?id=${item.companySegmentId}`} className={styles.navigatioinLink}>
                        View Details
                      </NavLink>
                    </Tooltip>
                  ]}
                >
                  <List.Item.Meta
                    title={<p>{item.companySegmentName}</p>}
                    description={<p>{item.details.replace('This context contains information about', '')} <br /> {item.sitemapUrl}</p>}
                  />
                </List.Item>
              )}
            />}
        </Card>
      </div>
    </>
  )
}

export default DataManagement