import { request } from './verb.services';

export const getAllUsers = (onSucess, onError) => {
  return request('Users', 'get', null, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status)
      } else {
      }
    });
};

export const setUserDeactive = (payload) => {
  return request(`Users/${payload}/Deactivate`, 'PATCH', null, true)
    .then(async ({ data }) => {
      console.log(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
      } else {
      }
    });
};


export const setUserActive = (payload) => {
  return request(`Users/${payload}/Activate`, 'PATCH', null, true)
    .then(async ({ data }) => {
      console.log(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
      } else {
      }
    });
};

export const createUser = (payload, onSucess, onError) => {
  return request(`Users`, 'POSt', payload, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess()
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};



export const updateUserRole = (payload, onSucess, onError) => {
  return request(`Users`, 'PUT', payload, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess()
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};


export const getUserDetails = (userId, onError) => {
  return request(`Users/${userId}`, 'get', null, true)
    .then(async ({ data }) => {
      console.log(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status)
      } else {
      }
    });
};

export const removeRoleFromUser = (roleId, userId, onSucess, onError) => {
  return request(`Users?userId=${userId}&roleId=${roleId}`, 'Delete', null, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess()
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status)
      } else {
      }
    });
};

export const assignRoleToUsers = (payload, onError) => {
  return request(`Users`, 'PUT', payload, true)
    .then(async ({ data }) => {
      console.log(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status)
      } else {
      }
    });
};

