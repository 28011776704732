import { request } from "./verb.services";

export const getAllRoles = (onSucess, onError) => {
  return request("Roles", "get", null, true)
    .then(async ({ data }) => {
      console.log(data);
      onSucess(data);
      return data;
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status);
      } else {
      }
    });
};

export const createRole = (payload, onSucess, onError) => {
  return request("Roles", "POST", payload, true)
    .then(async ({ data }) => {
      console.log(data);
      onSucess();
      return data;
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status);
      } else {
      }
    });
};

export const updateRole = (id, payload, onError) => {
  return request(`Roles/${id}`, "PUT", payload, true)
    .then(async ({ data }) => {
      console.log(data);
      return data;
    })
    .catch(function (error) {
      if (error.response) {
        onError();
      } else {
      }
    });
};

export const getRoleDetails = (id, onError) => {
  return request(`Roles/${id}`, "GET", null, true)
    .then(async ({ data }) => {
      console.log(data);
      return data;
    })
    .catch(function (error) {
      if (error.response) {
        onError();
      } else {
      }
    });
};

export const assignRoleToUsers = (payload, onError) => {
  return request(`Users`, "PUT", payload, true)
    .then(async ({ data }) => {
      console.log(data);
      return data;
    })
    .catch(function (error) {
      if (error.response) {
        onError();
      } else {
      }
    });
};

export const deleteRole = (companySegmentId, onSucess, onError) => {
  return request(`Roles/${companySegmentId}`, "DELETE", null, true)
    .then(async ({ data }) => {
      onSucess();
      return data;
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        onError(error.response.data);
      } else {
      }
    });
};
