import React from "react";
import styles from "./index.module.css";

const CustomButton = (props) => {
  const {
    text = "Sumit",
    onClick = () => { },
    isLoading = false,
    customStyle = {},
  } = props;
  return (
    <>
      <button
        disabled={isLoading}
        onClick={onClick}
        style={{ ...customStyle }}
        className={
          isLoading
            ? styles.CustomButtonContainer
            : styles.CustomButtonContainerDefaultState
        }
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        {text}
      </button>
    </>
  );
};

export default CustomButton;