import { setItem, clearStorage } from '../Utils/storage';
import { request } from './verb.services';

export const loginUser = async (payload, onSucess, onError) => {
  return request('Account/Login', 'post', payload, false)
    .then(async ({ data }) => {
      await setItem('user', data.user);
      await setItem('tokenContainer', { token: data.token });
      await setItem('Avatar', data.user.firstName[0].toUpperCase() + data.user.lastName[0].toUpperCase())
      onSucess()
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }

    });
};

export const resetPassword = async (payload, onSucess, onError) => {
  return request(`Account/ForgotPassword?emailAddress=${payload}`, 'PUT', payload, false)
    .then(async ({ data }) => {
      onSucess()
    })
    .catch(function (error) {
      onError()
      if (error.response) {
      } else {
      }

    });
};

export const changePassword = async (payload, onSucess, onError) => {
  return request(`Account/ResetPassword`, 'PUT', payload, false)
    .then(async ({ data }) => {
      onSucess()
    })
    .catch(function (error) {
      onError()
      if (error.response) {
      } else {
      }

    });
};

export const getCompanySetting = async (payload) => {
  return request(`Settings?domainUrl=${payload}`, 'post', null, false)
    .then(async ({ data }) => {
      await setItem('logo', data.companyLogoUrl);
      await setItem('logoStyle', { token: data.token });
      return data.companyLogoUrl
    })
    .catch(function (error) {
      if (error.response) {
        setItem('logo', undefined);
        setItem('logoStyle', undefined);
      } else {
      }

    });
};


export const logoutUser = () => {
  clearStorage();
};


export const getUserProfile = async () => {
  return request(`Users/LoggedInUser`, 'GET', null, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response) {
      } else {
      }

    });
};

export const updateUserProfile = async (userId, payload) => {
  return request(`Users/${userId}`, 'POST', payload, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response) {
      } else {
      }

    });
};

export const updateUserPassword = async (payload) => {
  return request(`Account/Password`, 'PATCH', payload, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response) {
      } else {
      }

    });
};