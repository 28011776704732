import { useState, useEffect } from 'react';
import { Button, Breadcrumb, Tooltip, Card, List, Skeleton, Form, Input, Select } from 'antd';
import { useNavigate, NavLink } from 'react-router-dom';
import { FileTextOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { getAllRoles } from '../../services/roles.services'
import { getAllSegments } from '../../services/segment.services'
import { createRole } from '../../services/roles.services';
import styles from './index.module.css'

const RoleManagement = () => {
  const navigate = useNavigate()
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const [addRole, setAddRole] = useState(false)
  const [loadingAddRole, setLoadingAddRole] = useState(false)
  const [options, setOptions] = useState([])


  const loadRoles = async () => {
    setLoading(true)
    await getAllRoles(onSucessRoles, onErrorRoles)
  }


  const loadSegment = async () => {
    setLoading(true)
    await getAllSegments(onSucessSegment, onErrorSegment)
  }
  const onSucessRoles = (data) => {
    setLoading(false)
    setRoles(data)
  }

  const onErrorRoles = (status) => {
    if (status == '401') {
      navigate('/Expired')

    }
    else {
    }
  }


  const onSucessSegment = (data) => {
    setLoading(false)
    const tempListSegment = data.filter((item) => item.companySegmentType === 1 && item.isDefault !== true);
    const tempListSitemaps = data.filter((item) => item.companySegmentType === 2);
    let temp1 = [{
      label: 'Segments',
      options:
        tempListSegment.map((item => {
          return { label: item.companySegmentName, value: item.companySegmentId }
        }))
      ,
    },
    {
      label: 'Sitemaps',
      options: tempListSitemaps.map((item => {
        return { label: item.companySegmentName, value: item.companySegmentId }
      })),
    }
    ];

    setOptions(temp1)
  }

  const onErrorSegment = (status) => {
    if (status == '401') {
      navigate('/Expired')

    }
    else {
    }
  }

  const onFinishAddRole = async (values) => {
    setLoadingAddRole(true)
    await createRole(values, onSucessRolesAdd, onErrorRolesAdd)
  };

  const onSucessRolesAdd = (data) => {
    setLoadingAddRole(false)
    setAddRole(false)
    loadRoles()
  }

  const onErrorRolesAdd = (status) => {
    if (status == '401') {
      navigate('/Expired')

    }
    else {
    }
  }
  useEffect(() => {
    loadRoles()
    loadSegment()
  }, [])



  return (
    <>
      <Card bordered title="Role Mangement" className={styles.cardContainer}
        style={{ paddingBottom: '10px' }}
      >
        <Breadcrumb
          items={[
            {
              title: <NavLink to="/dashboard">
                <span>Home</span>
              </NavLink>,
            },
            {
              title: 'Role Management',
            },

          ]}
        />

      </Card>

      <div className={styles.container}>

        <Card
          className={styles.cardContainer}
          bordered
          title={`Role(s)`}
          extra={[<Button
            onClick={() => { setAddRole(true) }}
            disabled={addRole}
            key="sider-menuitem-delete" style={{ marginRight: '20px' }}>
            Add Role
          </Button>]}

        >
          {addRole &&
            <Form
              name="normal_login"
              className={styles.formContainer}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishAddRole}
            >
              <Form.Item
                name="roleName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the name of the role!',
                  },
                ]}
              >
                <Input
                  disabled={loadingAddRole}
                  className={styles.formInputs}
                  placeholder="Enter Role Name"
                  prefix={<UserOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>
              <Form.Item
                name="companySegmentIds"
                rules={[
                  {
                    required: true,
                    message: 'Please select the role of the user!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  disabled={loadingAddRole}
                  allowClear
                  style={{ padding: '0px' }}
                  className={styles.formInputs}
                  placeholder="Role"
                  options={options}
                />
              </Form.Item>
              <Form.Item >
                <div className={styles.btnContainer}>
                  <Button disabled={loadingAddRole} style={{ color: 'red', borderColor: 'red' }} onClick={() => { setAddRole(false) }}>Cancel</Button>
                  <Button loading={loadingAddRole} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                  >Confirm</Button>
                </div>
              </Form.Item>
            </Form>
          }
          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={roles}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Tooltip placement="leftBottom" title={'Click Here to view details and edit this segment'} >
                      <NavLink to={`/rolemanagement/RoleDetails?id=${item.roleId}`} className={styles.navigatioinLink}>
                        View Details
                      </NavLink>
                    </Tooltip>
                  ]}
                >
                  <List.Item.Meta
                    title={<NavLink to={`/rolemanagement/RoleDetails?id=${item.roleId}`}>

                      {item.roleName}
                    </NavLink>}
                    description={<p>Contained Segments: {item.companySegments.length > 0 ?
                      item.companySegments.map(item => {
                        return (item.companySegmentName + ', ')
                      })
                      : 'No Segments added to this role'}
                      <br />
                      Allowed User Count: {item.userCount}
                    </p>}
                  />
                </List.Item>
              )}
            />
          }
        </Card>

        {/*         
        <div className={styles.TableContainer}>
          <DataTable data={DataTableData} />
        </div> */}
      </div>
    </>
  )
}

export default RoleManagement