import { request } from './verb.services';

export const getAllSegments = (onSucess, onError) => {
  return request('Companies/Segments', 'get', null, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status)
      } else {
      }
    });
};

export const createSegment = (payload, onSucess, onError) => {
  return request('Companies', 'POST', payload, true)
    .then(async ({ data }) => {
      onSucess(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        console.log('first', error.response.request)
        onError(JSON.parse(error.response.request.response))
      } else {
      }
    });
};

export const addDocumentToSegment = (companySegmentId, payload, onSucess, onError) => {
  return request(`Documents/${companySegmentId}`, 'PUT', payload, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};

export const getDocumentToSegment = (companySegmentId, onError) => {
  return request(`Documents/${companySegmentId}`, 'GET', null, true)
    .then(async ({ data }) => {
      console.log(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};

export const deleteDocumentSegment = (companySegmentId, documentId, onSucess, onError) => {
  return request(`CompanySegments/${companySegmentId}/Documents/${documentId}`, 'Delete', null, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess()
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};


export const getSegmentDetails = (companySegmentId, onError) => {
  return request(`Companies/SegmentDetails/${companySegmentId}`, 'GET', null, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};


export const deleteSegment = (companySegmentId, onSucess, onError) => {
  return request(`Companies/Segments/${companySegmentId}`, 'DELETE', null, true)
    .then(async ({ data }) => {
      onSucess()
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status, error.response.request.response)
      } else {
      }
    });
};

export const updateSegmentDescription = (companySegmentId, payload, onError) => {
  return request(`Companies/Segments/${companySegmentId}`, 'PUT', payload, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};


export const getSegmentRules = (companySegmentId, onError) => {
  return request(`Companies/SegmentRules/${companySegmentId}`, 'GET', null, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};

export const addSegmentRules = (payload, onError) => {
  return request(`Companies/SegmentRules`, 'POST', payload, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};

export const removeSegmentRules = (companySegmentId, segmentRuleId, onSucess, onError) => {
  return request(`Companies/Segments/${companySegmentId}/Rules/${segmentRuleId}/Delete`, 'DELETE', null, true)
    .then(async ({ data }) => {
      onSucess()
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};

export const getSegmentTrainingData = (companySegmentId, onError) => {
  return request(`Companies/SegmentTrainingData/${companySegmentId}`, 'GET', null, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};

export const addSegmentTrainingData = (payload, onError) => {
  debugger
  return request(`Companies/SegmentTrainingData`, 'POST', payload, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};

export const removeSegmentTrainingData = (companySegmentId, segmentRuleId, onSucess, onError) => {
  return request(`Companies/Segments/${companySegmentId}/SegmentTrainingData/${segmentRuleId}/Delete`, 'DELETE', null, true)
    .then(async ({ data }) => {
      onSucess()
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};


export const updateSegmentTrainingData = (trainingId, payload, onSucess, onError) => {
  return request(`Companies/SegmentTrainingData/${trainingId}`, 'PUT', payload, true)
    .then(async ({ data }) => {
      onSucess()
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError()
      } else {
      }
    });
};



export const getUserSegments = () => {
  return request('Companies/LoggedInUserSegments', 'get', null, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
    });
};
