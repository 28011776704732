import { request } from './verb.services';

export const getAllSitemaps = (onSucess, onError) => {
  return request('Sitemaps', 'get', null, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status)
      } else {
      }
    });
};

export const createNewSitemap = (payload, onSucess, onError) => {
  return request('Sitemaps', 'POST', payload, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status)
      } else {
      }
    });
};


export const deleteSitemap = (data, onSucess, onError) => {
  return request(`CompanySegments/${data.companySegmentId}/Sitemaps/${data.id}`, 'DELETE', null, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess(data)
      return data
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status)
      } else {
      }
    });
};
