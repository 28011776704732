import { useLayoutEffect, useState } from "react";
import {
  Button,
  Breadcrumb,
  Input,
  Card,
  Select,
  List,
  Form,
  Skeleton,
  Switch,
  Popconfirm,
  Avatar,
} from "antd";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { getAllSegments } from "../../../services/segment.services";
import {
  updateRole,
  getRoleDetails,
  deleteRole,
} from "../../../services/roles.services";
import { UserOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import styles from "./index.module.css";

const SegmentDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [optionsSegments, setOptionsSegment] = useState([]);
  const [options, setOptions] = useState([]);
  const [segmentData, setSegmentData] = useState({});
  const [segmentDataLoading, setSegmentDataLoading] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSider, setShowSider] = useState(false);
  const [loadingChangeDetails, setLoadingChangeDetails] = useState(false);
  const [showAddSegment, setShowAddSegment] = useState(false);
  const [loadingAddSegment, setLoadingAddSegment] = useState(false);
  const [showAddSitemap, setShowAddSitemap] = useState(false);
  const [loadingAddSitemap, setLoadingAddSitemap] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);
  const compaySegment = [];

  const loadDetails = async () => {
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      setLoading(true);
      const data = await getRoleDetails(segmentID, onErrorSegments);
      let temp = [];
      setSegmentData(data);
      data.companySegments?.map((file) => {
        temp.push({ [file.companySegmentId]: false });
      });

      setSegmentDataLoading(temp);
      form.setFieldsValue({
        ["roleName"]: data.roleName,
      });
      compaySegment.push(...data.companySegments);
      loadSegment();
    }
  };

  const onErrorSegments = (status) => {
    if (status == "401") {
      navigate("/Expired");
    } else {
    }
  };

  const siderSwitchToggle = () => {
    setShowSider(!showSider);
  };

  const loadSegment = async () => {
    await getAllSegments(onSucessSegment, onErrorSegment);
  };

  const onSucessSegment = (data) => {
    const allSegment = data;
    let tempListSegment = allSegment.filter(
      (item) => item.companySegmentType === 1 && item.isDefault !== true
    );
    tempListSegment = tempListSegment.filter(
      (item1) =>
        !compaySegment.some(
          (item2) => item1.companySegmentId === item2.companySegmentId
        )
    );
    let temp1 = tempListSegment.map((item) => {
      return { label: item.companySegmentName, value: item.companySegmentId };
    });
    let tempListSitemaps = allSegment.filter(
      (item) => item.companySegmentType === 2 && item.isDefault !== true
    );
    tempListSitemaps = tempListSitemaps.filter(
      (item1) =>
        !compaySegment.some(
          (item2) => item1.companySegmentId === item2.companySegmentId
        )
    );
    let temp2 = tempListSitemaps.map((item) => {
      return { label: item.companySegmentName, value: item.companySegmentId };
    });
    setOptionsSegment(temp1);
    setOptions(temp2);
    setLoading(false);
  };

  const onErrorSegment = (status) => {
    if (status == "401") {
      navigate("/Expired");
    } else {
    }
  };

  const removeDocument = async (docId) => {
    setDeletingFile(true);
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: true });
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    const values = segmentData.companySegments.filter(
      (item) => item.companySegmentId !== docId
    );
    const temp = [];
    values.map((item) => {
      temp.push(item.companySegmentId);
    });
    if (segmentID) {
      const data = await updateRole(
        segmentID,
        { roleName: segmentData.roleName, companySegmentIds: temp },
        onErrorUpdate
      );
      loadDetails();
      setDeletingFile(false);
      setLoadingAddSegment(false);
      setShowAddSegment(false);
    }
  };

  const onSucess = async (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: false });
    loadDetails();
    Swal.fire({
      icon: "success",
      text: "The role has been scuessfully deleted",
      showConfirmButton: false,
      timer: 2500,
    });
    navigate("/roleManagement");
  };

  const onError = (message) => {
    setLoading(false);
    Swal.fire({
      icon: "error",
      text: message,
      showConfirmButton: false,
      timer: 2500,
    });
  };

  const DeleteSegment = async () => {
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      setLoading(true);
      const data = await deleteRole(segmentID, onSucess, onError);
    }
  };

  const onFinishUpdateDetals = async (values) => {
    setLoadingChangeDetails(true);
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    const temp = [];
    segmentData?.companySegments.map((item) => {
      temp.push(item.companySegmentId);
    });
    if (segmentID) {
      const data = await updateRole(
        segmentID,
        { ...values, companySegmentIds: temp },
        onErrorUpdate
      );
      loadDetails();
      setLoadingChangeDetails(false);
      setShowSider(false);
    }
  };

  const onErrorUpdate = () => {
    setLoadingChangeDetails(false);
    Swal.fire({
      icon: "error",
      text: "We were ran into a problem while trying to update youre segment, Please try again later",
      showConfirmButton: false,
      timer: 2500,
    });
  };

  const confirm = (e) => {
    DeleteSegment();
  };

  const cancel = (e) => { };

  const onFinishUpdateSegment = async (values) => {
    setLoadingAddSegment(true);
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    const temp = [];
    segmentData.companySegments.map((item) => {
      temp.push(item.companySegmentId);
    });
    values.companySegmentIds.map((item) => {
      temp.push(item);
    });
    if (segmentID) {
      const data = await updateRole(
        segmentID,
        { roleName: segmentData.roleName, companySegmentIds: temp },
        onErrorUpdate
      );
      loadDetails();
      setLoadingAddSegment(false);
      setShowAddSegment(false);
    }
  };

  const onFinishUpdateSitemap = async (values) => {
    setLoadingAddSitemap(true);
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    const temp = [];
    segmentData.companySegments.map((item) => {
      temp.push(item.companySegmentId);
    });
    values.companySegmentIds.map((item) => {
      temp.push(item);
    });
    if (segmentID) {
      const data = await updateRole(
        segmentID,
        { roleName: segmentData.roleName, companySegmentIds: temp },
        onErrorUpdate
      );
      loadDetails();
      setLoadingAddSitemap(false);
      setShowAddSitemap(false);
    }
  };

  useLayoutEffect(() => {
    loadDetails();
  }, []);

  return (
    <>
      <Card
        bordered
        title={segmentData.roleName}
        className={styles.cardContainer}
        style={{ paddingBottom: "10px" }}
      >
        <Breadcrumb
          items={[
            {
              title: (
                <NavLink to="/dashboard">
                  <span>Home</span>
                </NavLink>
              ),
            },
            {
              title: (
                <NavLink to="/roleManagement">
                  <span>Role Management</span>
                </NavLink>
              ),
            },

            {
              title: "Role Details",
            },
            {
              title: segmentData.roleName,
            },
          ]}
        />
      </Card>
      <div className={styles.container}>
        <Card
          bordered
          title="Role Details"
          className={styles.cardContainer}
          extra={[
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                alignItems: "end",
              }}
            >
              <Popconfirm
                title="Delete the Role"
                description="Are you sure to delete this role?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button key="sider-menuitem-delete" danger>
                  Delete Role
                </Button>{" "}
              </Popconfirm>
              <div className={styles.switchDiv}>
                <span>Enable Edit Mode</span>
                <Switch
                  disabled={loadingChangeDetails}
                  checkedChildren="Edit"
                  unCheckedChildren="View"
                  checked={showSider}
                  onChange={siderSwitchToggle}
                />
              </div>
            </div>,
          ]}
        >
          <Form
            form={form}
            className={styles.formContainer}
            initialValues={{
              ["roleName"]: segmentData?.roleName,
            }}
            onFinish={onFinishUpdateDetals}
          >
            <Form.Item
              name="roleName"
              rules={[
                {
                  required: true,
                  message: "Please input the name of your Role!",
                },
              ]}
            >
              <Input
                disabled={!showSider || loadingChangeDetails}
                className={styles.formInputs}
                placeholder="Enter Role Name"
                prefix={
                  <UserOutlined
                    className="site-form-item-icon"
                    style={{ marginRight: "10px" }}
                  />
                }
              />
            </Form.Item>
            <Form.Item>
              {showSider && (
                <div className={styles.btnContainer}>
                  <Button
                    disabled={loadingChangeDetails}
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => {
                      setShowSider(false);
                      form.setFieldsValue({
                        roleName: segmentData?.roleName,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={loadingChangeDetails}
                    style={{ color: "green", borderColor: "green" }}
                    htmlType="submit"
                  >
                    Confirm
                  </Button>
                </div>
              )}
            </Form.Item>
          </Form>
        </Card>
        <Card
          className={styles.cardContainer}
          bordered
          title={`Segments Contained in this Role`}
          extra={[
            <Button
              key="sider-menuitem-delete"
              style={{ marginRight: "30px" }}
              onClick={() => {
                setShowAddSegment(true);
              }}
            >
              Add Segment
            </Button>,
          ]}
        >
          {showAddSegment && (
            <Form
              className={styles.formContainer}
              onFinish={onFinishUpdateSegment}
            >
              <Form.Item
                name="companySegmentIds"
                rules={[
                  {
                    required: true,
                    message: "Please select the segment to add to this role!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ padding: "0px" }}
                  className={styles.formInputs}
                  placeholder="Role"
                  options={optionsSegments}
                  disabled={loadingAddSegment}
                />
              </Form.Item>
              <Form.Item>
                <div className={styles.btnContainer}>
                  <Button
                    disabled={loadingAddSegment}
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => {
                      setShowAddSegment(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={loadingAddSegment}
                    style={{ color: "green", borderColor: "green" }}
                    htmlType="submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}
          {loading ? (
            <Skeleton />
          ) : (
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segmentData?.companySegments?.filter(
                (item) =>
                  item.companySegmentType === 1 && item.isDefault !== true
              )}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      disabled={deletingFile}
                      key="sider-menuitem-delete"
                      loading={segmentDataLoading[item.companySegmentId]}
                      onClick={() => {
                        removeDocument(item.companySegmentId);
                      }}
                    >
                      Remove Segment
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <p>{item.companySegmentName}</p>
                        <p style={{ fontWeight: 400 }}>
                          {item.details.replace(
                            "This context contains information about",
                            ""
                          )}
                        </p>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          )}
        </Card>
        <Card
          className={styles.cardContainer}
          bordered
          title={`Sitemaps/URLs Contained in this Role`}
          extra={[
            <Button
              key="sider-menuitem-delete"
              style={{ marginRight: "30px" }}
              onClick={() => {
                setShowAddSitemap(true);
              }}
            >
              Add Sitemap/URL
            </Button>,
          ]}
        >
          {showAddSitemap && (
            <Form
              className={styles.formContainer}
              onFinish={onFinishUpdateSitemap}
            >
              <Form.Item
                name="companySegmentIds"
                rules={[
                  {
                    required: true,
                    message: "Please select the sitemap to add to this role!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ padding: "0px" }}
                  className={styles.formInputs}
                  placeholder="Role"
                  disabled={loadingAddSitemap}
                  options={options}
                />
              </Form.Item>
              <Form.Item>
                <div className={styles.btnContainer}>
                  <Button
                    disabled={loadingAddSitemap}
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => {
                      setShowAddSitemap(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={loadingAddSitemap}
                    style={{ color: "green", borderColor: "green" }}
                    htmlType="submit"
                  >
                    Confirm
                  </Button>
                </div>
              </Form.Item>
            </Form>
          )}
          {loading ? (
            <Skeleton />
          ) : (
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segmentData?.companySegments?.filter(
                (item) => item.companySegmentType === 2
              )}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      disabled={deletingFile}
                      key="sider-menuitem-delete"
                      loading={segmentDataLoading[item.companySegmentId]}
                      onClick={() => {
                        removeDocument(item.companySegmentId);
                      }}
                    >
                      Remove Sitemap/URL
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <p>{item.companySegmentName}</p>
                        <p style={{ fontWeight: 400 }}>
                          {item.details.replace(
                            "This context contains information about",
                            ""
                          )}
                        </p>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          )}
        </Card>
        <Card
          className={styles.cardContainer}
          style={{ paddingBottom: "80px" }}
          bordered
          title={`Users this role is assigned to`}
        // extra={[<Button key="sider-menuitem-delete" style={{ marginRight: '30px' }}>
        //   Assign to New User
        // </Button>]}
        >
          {loading ? (
            <Skeleton />
          ) : (
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segmentData?.users}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Button
                      onClick={() => {
                        navigate(
                          `/usermanagement/userdetails?id=${item.userId}`
                        );
                      }}
                    >
                      View Details
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                      />
                    }
                    title={
                      <NavLink to={`/usermanagement/userdetails?id=${item.userId}`} >
                        {item.firstName + " " + item.lastName}
                      </NavLink>
                    }
                    description={<p>{item.email}</p>}
                  />
                </List.Item>
              )}
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default SegmentDetails;
