import { useLayoutEffect, useState } from 'react';
import { Button, Breadcrumb, Input, Card, List, Form, Skeleton, Switch, Popconfirm } from 'antd';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { getSegmentDetails, deleteDocumentSegment, deleteSegment, updateSegmentDescription } from '../../../services/segment.services';
import { UserOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import styles from './index.module.css'


const SegmentDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm();
  const [segmentData, setSegmentData] = useState({})
  const [segmentDataLoading, setSegmentDataLoading] = useState({})
  const [loading, setLoading] = useState(false)
  const [showSider, setShowSider] = useState(false);
  const [loadingChangeDetails, setLoadingChangeDetails] = useState(false)

  const loadDetails = async () => {
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      setLoading(true)
      const data = await getSegmentDetails(segmentID, onErrorSegments)
      let temp = []
      setSegmentData(data)
      data?.files.map(file => {
        temp.push({ [file.documentId]: false })
      })
      setSegmentDataLoading(temp)
      form.setFieldsValue({
        ["segmentName"]: data?.companySegmentName,
        ["segmentDetails"]: data?.details?.replace('This context contains information about', '')
      });
      setLoading(false)
    }
  }
  const onErrorSegments = (status, text) => {
    if (status == '401') {
      navigate('/Expired')
    }
    else {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        text: text ? text : 'We were ran into a problem while trying delete the requested segment, Please try again later',
        showConfirmButton: false,
        timer: 2500
      })
    }
  }
  const siderSwitchToggle = () => {
    setShowSider(!showSider);
  };


  const removeDocument = async (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: true })
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    await deleteDocumentSegment(segmentID, docId, () => { onSucess(docId) }, () => { onError(docId) })
  }


  const onSucess = async (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: false })
    loadDetails()
    Swal.fire({
      icon: 'success',
      text: 'The document has been scuessfully deleted',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onError = (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: false })
    setLoading(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying delete the requested document, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const DeleteSegment = async () => {
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      setLoading(true)
      const data = await deleteSegment(segmentID, () => {
        Swal.fire({
          icon: 'success',
          text: 'The segment has been scuessfully deleted',
          showConfirmButton: false,
          timer: 2500
        })
        navigate('/dataManagement')
      }, (status, text) => {
        onErrorSegments(status, text)
      })
    }
  }


  const onFinishUpdateDetals = async (values) => {
    setLoadingChangeDetails(true)
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      const data = await updateSegmentDescription(segmentID, values, onErrorUpdate)
      loadDetails()
      setLoadingChangeDetails(false)
      setShowSider(false)
    }
  };
  const onErrorUpdate = () => {
    setLoadingChangeDetails(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying to update youre segment, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onSuccessRoles = () => {
    const array1 = [
      { id: 1, name: 'John' },
      { id: 2, name: 'Alice' },
      { id: 4, name: 'Eve' },
    ];

    const array2 = [
      { id: 2, name: 'Alice' },
      { id: 4, name: 'Eve' },
    ];

    // Remove elements from array1 that do not exist in array2
    const filteredArray1 = array1.filter((item1) => array2.some((item2) => item1.id === item2.id));

    console.log(filteredArray1);
  }

  const confirm = (e) => {
    DeleteSegment()
  };
  const cancel = (e) => {
  };
  useLayoutEffect(() => {
    loadDetails()
  }, [])

  return (
    <>
      <Card bordered title={segmentData?.companySegmentName} className={styles.cardContainer}
        style={{ paddingBottom: '10px' }}

      >
        <Breadcrumb
          items={[
            {
              title: <NavLink to="/dashboard">
                <span>Home</span>
              </NavLink>,
            },
            {
              title: <NavLink to="/dataManagement">
                <span>Data Management</span>
              </NavLink>
              ,
            },

            {
              title: 'Sitemap Details',
            },
            {
              title: "Tricension",
            },
          ]}
        />

      </Card>

      <div className={styles.container}>

        <Card bordered title="Segment Details"
          className={styles.cardContainer}
          extra={[
            <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', alignItems: 'end' }}>
              <Popconfirm
                title="Delete the Sitemap/URL"
                description="Are you sure to delete this Sitemap/URL?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button key="sider-menuitem-delete" danger>
                  Delete Sitemap/URL
                </Button>  </Popconfirm>
              <div className={styles.switchDiv}>
                <span >Enable Edit Mode</span>
                <Switch
                  disabled={loadingChangeDetails}
                  checkedChildren="Edit"
                  unCheckedChildren="View"
                  checked={showSider}
                  onChange={siderSwitchToggle}
                />
              </div>
            </div>
          ]}
        >
          <Form
            form={form}
            className={styles.formContainer}
            initialValues={{
              ["segmentName"]: segmentData?.companySegmentName,
              ["segmentDetails"]: segmentData?.details?.replace('This context contains information about', '')
            }}
            onFinish={onFinishUpdateDetals}
          >
            <Form.Item
              name="segmentName"
              rules={[
                {
                  required: true,
                  message: 'Please input the name of your segment!',
                },
              ]}
            >
              <Input
                disabled={!showSider || loadingChangeDetails}
                className={styles.formInputs}
                placeholder="Enter Segment Name"
                prefix={<UserOutlined className="site-form-item-icon"
                  style={{ marginRight: '10px' }} />
                }
              />
            </Form.Item>
            <Form.Item
              name="segmentDetails"
              rules={[
                {
                  required: true,
                  message: 'Please input the description of your segment in the form of keyword!',
                },
              ]}
            >
              <Input
                disabled={!showSider || loadingChangeDetails}
                className={styles.formInputs}
                placeholder="Enter Segment Description"
                prefix={<UserOutlined className="site-form-item-icon"
                  style={{ marginRight: '10px' }} />
                }
              />
            </Form.Item>

            <Form.Item >
              {showSider &&
                <div className={styles.btnContainer}>
                  <Button disabled={loadingChangeDetails} style={{ color: 'red', borderColor: 'red' }} onClick={() => {
                    setShowSider(false); form.setFieldsValue({
                      segmentName: segmentData?.companySegmentName,
                      segmentDetails: segmentData?.details?.replace('This context contains information about', '')
                    });
                  }}>Cancel</Button>
                  <Button loading={loadingChangeDetails} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                  >Confirm</Button>
                </div>
              }

            </Form.Item>
          </Form>
        </Card>
        <Card
          className={styles.cardContainer}
          bordered
          title={`URL/Sitemaps`}
        >
          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segmentData?.urLs}
              renderItem={(item) => (
                <List.Item

                >
                  <List.Item.Meta
                    title={
                      <p >{item.sitemapUrl}</p>
                    }
                    description={
                      <p style={{ color: `${item.process === 1 ? 'black' : item.process === 2 ? 'green' : 'red'}` }}>{`Status: ${item.process === 3 ? 'Failed To Process Please Remove and add againi' : item.process === 2 ? 'Processed' : 'Processing'}`}</p>
                    }
                  />
                </List.Item>
              )}
            />
          }
        </Card>
        <Card
          className={styles.cardContainer}
          style={{ paddingBottom: '80px' }}
          bordered
          title={`Roles Containing this URL/Sitemap`}
        // extra={[<Button key="sider-menuitem-delete" style={{ marginRight: '30px' }}>
        //   Add New Role
        // </Button>]}

        >
          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segmentData?.roles}
              renderItem={(item) => (
                <List.Item
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                  actions={[
                    <Button key="sider-menuitem-delete" onClick={() => {
                      navigate(`/rolemanagement/RoleDetails?id=${item.roleId}`)
                    }}>
                      View Details
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    title={<p>{item.roleName}</p>}
                    description={<p>{'Allowed Users Count:' + item.userCount} <br /> {item.sitemapUrl}</p>}
                  />

                </List.Item>
              )}
            />}
        </Card>
      </div>
    </>
  )
}

export default SegmentDetails