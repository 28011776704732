import { request } from './verb.services';

export const getAllChats = (sucess, onError) => {
  return request('Chats', 'get', null, true)
    .then(async ({ data }) => {
      sucess()
      return data
    })
    .catch(function (error) {
      if (error.response.request.status == '401') {
        onError()
      } else {
      }
    });
};

export const createChat = (payload, onError) => {
  return request('Chats', 'POST', payload, true)
    .then(async ({ data }) => {
      return data
    })
    .catch(function (error) {
      if (error.response.request.status == '401') {
        onError()
      } else {
      }
    });
};

export const getChatById = (payload, success, onError) => {
  return request(`Chats/${payload}`, 'GET', null, true)
    .then(async ({ data }) => {
      console.log(data)
      success()
      return data
    })
    .catch(function (error) {
      if (error.response.request.status == '401') {
        onError()
      } else {
      }
    });
};

export const sendChatMessage = (id, payload, onSucess, onError) => {
  return request(`Chats/${id}`, 'POST', payload, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess(data)
    })
    .catch(function (error) {
      if (error.response) {
        onError(payload, error.response.request.status)
      } else {
      }
    });
};


export const clearChatMessage = (onSucess, onError) => {
  return request(`Chats/DeleteChatHistory`, 'DELETE', null, true)
    .then(async ({ data }) => {
      console.log(data)
      onSucess(data)
    })
    .catch(function (error) {
      if (error.response) {
        onError(error.response.request.status)
      } else {
      }
    });
};

