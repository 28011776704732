import React from 'react'
import styles from './index.module.css'
const NotFound = () => {
  return (
    <div className={styles.container}>
      <h1>404</h1>
      <p>Oops! Something is wrong.</p>
    </div>
  )
}

export default NotFound