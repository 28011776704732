import { useEffect, useLayoutEffect, useState } from 'react';
import styles from './index.module.css'
import { Button, Input, Breadcrumb, Card, Switch, List, Form } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import { getUserProfile, updateUserProfile, updateUserPassword } from '../../services/auth.services';
import { NavLink } from 'react-router-dom';

const Profile = () => {
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();

  const [appTitle, setAppTitle] = useState('Layoutz');
  const [showSider, setShowSider] = useState(false);
  const [changePassword, setChangePassword] = useState(false)
  const [userDetails, setUserDetails] = useState({ firstName: '', lastName: '' })
  const [loadingChangeUser, setLoadingChangeUser] = useState(false)
  const [loadingChangePassword, setLoadingChangePassword] = useState(false)
  const siderSwitchToggle = () => {
    setShowSider(!showSider);
  };

  const onLoad = async () => {

    const data = await getUserProfile()
    setUserDetails(data)
    form.setFieldsValue({
      firstName: data.firstName,
      lastName: data.lastName
    });
  }
  const onFinishUpdateUser = async (values) => {
    setLoadingChangeUser(true)
    await updateUserProfile(userDetails.userId, values)
  };

  const onFinishUpdatePassword = async (values) => {
    setLoadingChangePassword(true)
    await updateUserPassword(values)
    setChangePassword(false)
    setLoadingChangePassword(false)
  };

  const validateFieldsMatch = (rule, value, callback) => {
    const otherFieldValue = passwordForm.getFieldValue('newPassword');
    if (value !== otherFieldValue) {
      callback('Passwords do not match!');
    } else {
      callback();
    }
  };

  const validatePassword = (rule, value, callback) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
    if (!regex.test(value)) {
      callback('Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.');
    } else if (value.length < 8) {
      callback('Password must be at least 8 characters long.');
    } else {
      callback();
    }
  };

  useLayoutEffect(() => {
    onLoad()
  }, [])

  return (
    <>
      <Card bordered title="Profile" className={styles.cardContainer}
        style={{ paddingBottom: '10px' }}

      >
        <Breadcrumb
          items={[
            {
              title: <NavLink to="/dashboard">
                <span>Home</span>
              </NavLink>
            },
            {
              title: "Profile",
            },
          ]}
        />

      </Card>
      <div className={styles.container}>
        <Card bordered title="User Profile" className={styles.cardContainer}
          extra={
            <div className={styles.switchDiv}>
              <span >Enable Edit Mode</span>
              <Switch
                disabled={loadingChangeUser}
                checkedChildren="Edit"
                unCheckedChildren="View"
                checked={showSider}
                onChange={siderSwitchToggle}
              />
            </div>
          }>
          <>
            <Form
              form={form}
              className={styles.formContainer}
              initialValues={{
                ["firstName"]: userDetails.firstName,
                ["lastName"]: userDetails.lastName
              }}
              onFinish={onFinishUpdateUser}
            >
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the name of your new segment!',
                  },
                ]}
              >
                <Input
                  disabled={!showSider || loadingChangeUser}
                  className={styles.formInputs}
                  placeholder="Enter Segment Name"
                  prefix={<UserOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the description of your new segment in the form of keyword!',
                  },
                ]}
              >
                <Input
                  disabled={!showSider || loadingChangeUser}
                  className={styles.formInputs}
                  placeholder="Enter Segment Description"
                  prefix={<UserOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>

              <Form.Item >
                {showSider &&
                  <div className={styles.btnContainer}>
                    <Button disabled={loadingChangeUser} style={{ color: 'red', borderColor: 'red' }} onClick={() => {
                      setShowSider(false); form.setFieldsValue({
                        firstName: userDetails.firstName,
                        lastName: userDetails.lastName
                      });
                    }}>Cancel</Button>
                    <Button loading={loadingChangeUser} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                    >Confirm</Button>
                  </div>
                }

              </Form.Item>
            </Form>

          </>
        </Card>
        <Card
          className={styles.cardContainer}
          bordered
          title="Password"
          extra={
            <Button key="sider-menuitem-delete" onClick={() => { setChangePassword(!changePassword) }}>
              Change Password
            </Button>
          }
        >
          {changePassword &&
            <Form
              form={passwordForm}
              className={styles.formContainer}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishUpdatePassword}
            >

              <Form.Item
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Current Password!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter your current password"
                  className={styles.formInputs}
                  disabled={loadingChangePassword}
                  prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: '10px' }} />}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please input your New Password!',
                  },
                  { validator: validatePassword },

                ]}
              >
                <Input.Password
                  placeholder="Enter your New Password"
                  className={styles.formInputs}
                  disabled={loadingChangePassword}
                  prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: '10px' }} />}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item
                name="confirmNewPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please re-enter your New Password!',
                  },
                  { validator: validateFieldsMatch },

                ]}
              >
                <Input.Password
                  placeholder="Confirm your new password"
                  disabled={loadingChangePassword}

                  className={styles.formInputs}
                  prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: '10px' }} />}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              <Form.Item >
                <div className={styles.btnContainer}>
                  <Button disabled={loadingChangePassword} style={{ color: 'red', borderColor: 'red' }} onClick={() => {
                    setChangePassword(false);
                    passwordForm.setFieldsValue({
                      currentPassword: '',
                      newPassword: '',
                      confirmNewPassword: ''
                    });
                  }}>Cancel</Button>
                  <Button
                    loading={loadingChangePassword}
                    style={{ color: 'green', borderColor: 'green' }}
                    htmlType="submit"
                  >Confirm</Button>
                </div>

              </Form.Item>
            </Form>
          }
        </Card>
        <Card
          className={styles.cardContainer}
          bordered
          title={`Assigned Roles`}
        >
          <List
            bordered
            itemLayout="horizontal"
            dataSource={userDetails.applicationRoles}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={<p>{userDetails.isCompanyAdmin ? 'Admin User' : item.roleName}</p>}
                  description={!userDetails.isCompanyAdmin && `Segments:' ${item.companySegments}`}
                />
              </List.Item>
            )}
          />
        </Card>
      </div>
    </ >
  )
}
export default Profile