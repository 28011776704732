import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Tooltip, Card, List, Avatar, Switch, Skeleton, Form, Input, Select } from 'antd';
import { useNavigate, NavLink } from 'react-router-dom';
import { getAllUsers, createUser } from '../../services/userManagement.services'
import { FileTextOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { getAllRoles } from '../../services/roles.services';
import Swal from 'sweetalert2';

import styles from './index.module.css'

const UserManagement = () => {
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [addUser, setAddUser] = useState(false)
  const [loadingAddUser, setLoadingAddUser] = useState(false)
  const [optionsRoles, setOptionsRoles] = useState([])
  const [companyAdmin, setCompanyAdmin] = useState(false)

  const loadRoles = async () => {
    await getAllRoles(onSucessRoles, onErrorRole)
  }

  const onSucessRoles = (data) => {
    const allSegment = data
    let temp1 = allSegment.map((item => {
      return { label: item.roleName, value: item.roleId }
    }))
    setOptionsRoles(temp1)
    setLoading(false);
  }

  const onErrorRole = (status) => {
    if (status == '401') {
      navigate('/Expired')
    }
    else {
    }
  }

  const onSucessUser = (data) => {
    setUsers(data)
    loadRoles()
  }

  const onErrorUsers = (status) => {
    if (status == '401') {
      navigate('/Expired')
    }
    else {

    }
  }

  const loadUsers = async () => {
    setLoading(true)
    await getAllUsers(onSucessUser, onErrorUsers)
  }

  const onFinishAddUser = async (values) => {
    setLoadingAddUser(true)
    await createUser({ ...values, isCompanyAdmin: companyAdmin }, onSucessAddNew, onErrorAddNew)
  };
  const onSucessAddNew = (data) => {
    loadUsers()
    setAddUser(false)
    setLoadingAddUser(false)
    Swal.fire({
      icon: 'success',
      text: 'The new user has been scuessfully added',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onErrorAddNew = (status) => {
    if (status == '401') {
      navigate('/Expired')
    }
    else {
      setAddUser(false)
      setLoadingAddUser(false)
      Swal.fire({
        icon: 'error',
        text: 'We were ran into a problem while trying add the requested user, Please try again later',
        showConfirmButton: false,
        timer: 2500
      })
    }
  }

  const siderSwitchToggle = () => {
    setCompanyAdmin(!companyAdmin);
  };

  useEffect(() => {
    loadUsers()
  }, [])
  return (
    <>
      <Card bordered title="User Mangement" className={styles.cardContainer}
        style={{ paddingBottom: '10px' }}
      >
        <Breadcrumb
          items={[
            {
              title: <NavLink to="/dashboard">
                <span>Home</span>
              </NavLink>,
            },
            {
              title: 'User Management',
            },

          ]}
        />

      </Card>

      <div className={styles.container}>

        <Card
          className={styles.cardContainer}
          bordered
          title={`User(s)`}
          extra={[<Button onClick={() => { setAddUser(true) }}
            key="sider-menuitem-delete"
            disabled={addUser}
            style={{ marginRight: '20px' }}>
            Add User
          </Button>]}

        >
          {addUser &&

            <Card
              className={styles.cardContainer}
              bordered
              title={`Add New User`}
              extra={[<div className={styles.switchDiv}>
                <span >Set As Admin</span>
                <Switch
                  disabled={loadingAddUser}
                  checkedChildren="Admin"
                  unCheckedChildren="User"
                  checked={companyAdmin}
                  onChange={siderSwitchToggle}
                />
              </div>]}

            >
              <Form
                name="normal_login"
                className={styles.formContainer}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinishAddUser}
              >
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the first name of the user!',
                    },
                  ]}
                >
                  <Input
                    disabled={loadingAddUser}
                    className={styles.formInputs}
                    placeholder="Enter User First Name"
                    prefix={<UserOutlined className="site-form-item-icon"
                      style={{ marginRight: '10px' }} />
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the last name of the user!',
                    },
                  ]}
                >
                  <Input
                    disabled={loadingAddUser}
                    className={styles.formInputs}
                    placeholder="Enter User Last Name"
                    prefix={<UserOutlined className="site-form-item-icon"
                      style={{ marginRight: '10px' }} />
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the email address of the user!',
                    },
                  ]}
                >
                  <Input
                    disabled={loadingAddUser}
                    className={styles.formInputs}
                    placeholder="Enter Email of User"
                    prefix={<MailOutlined className="site-form-item-icon"
                      style={{ marginRight: '10px' }} />
                    }
                  />
                </Form.Item>
                {!companyAdmin &&
                  <Form.Item
                    name="roleIds"
                    rules={[
                      {
                        required: true,
                        message: 'Please select the role of the user!',
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ padding: '0px' }}
                      className={styles.formInputs}
                      placeholder="Role"
                      options={optionsRoles}
                    />
                  </Form.Item>
                }
                <Form.Item >
                  <div className={styles.btnContainer}>
                    <Button disabled={loadingAddUser} style={{ color: 'red', borderColor: 'red' }} onClick={() => { setAddUser(false) }}>Cancel</Button>
                    <Button loading={loadingAddUser} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                    >Confirm</Button>
                  </div>
                </Form.Item>
              </Form>
            </Card>
          }
          {loading ? <Skeleton loading={true} active avatar className={styles.ChatQuestionConatiner} />
            :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={users}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Tooltip placement="leftBottom" title={'Click Here to view details and edit this segment'} >
                      <NavLink to={`/usermanagement/userdetails?id=${item.userId}`} className={styles.navigatioinLink}>
                        View Details
                      </NavLink>
                    </Tooltip>
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
                    title={<NavLink to={`/usermanagement/userdetails?id=${item.userId}`}>
                      {item.firstName + ' ' + item.lastName}
                    </NavLink>}
                    description={<p>{item.email}
                      <br />
                      Assigned Roles: {item.roles}
                    </p>}
                  />
                </List.Item>
              )}
            />
          }
        </Card>

        {/*         
        <div className={styles.TableContainer}>
          <DataTable data={DataTableData} />
        </div> */}
      </div>
    </>
  )
}

export default UserManagement