import React, { useState } from 'react';
import { InboxOutlined, FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { addDocumentToSegment } from '../../services/segment.services';
import styles from './index.module.css'
const { Dragger } = Upload;

const FileUploader = ({ close }) => {
  const location = useLocation()
  const formData = new FormData();
  const [filesList, setFilesList] = useState([])
  const [loadingUpload, setLoadingUpload] = useState(false)
  let tempList = []
  const checkFile = (file) => {
    const acceptedExtensions = ['pdf', 'doc', 'docx', 'txt', 'csv'];
    const fileExtension = file.name.split('.').pop();
    if (!acceptedExtensions.includes(fileExtension.toLowerCase())) {
      message.error('You can only upload PDF, Word, text, or CSV files!');
      return false;
    }
    else {
      tempList.push(file)
      setFilesList(tempList)
    }
    return true;

  };

  const handleFileUpload = async () => {
    setLoadingUpload(true)
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const formData = new FormData();
    filesList.map(files => {
      formData.append("files", files);
    })
    await addDocumentToSegment(id, formData, () => { onSucess() }, () => { onError() })
  }


  const removeElementFromList = (itemToRemove) => {
    const list = filesList
    tempList = tempList.filter((item) => item !== itemToRemove);
    const newList = list.filter((item) => item !== itemToRemove);
    setFilesList(newList)
  }

  const onSucess = async () => {
    setLoadingUpload(false)
    Swal.fire({
      icon: 'success',
      text: 'New File(s) have been scuessfully added',
      showConfirmButton: false,
      timer: 2500
    })
    close()
  }
  const onError = () => {
    setLoadingUpload(false)
    Swal.fire({
      icon: 'error',
      text: 'We were unable to load the requested file(s), Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const properties = {
    name: 'file',
    multiple: true,
    customRequest: () => { },
    onChange(info) {
    },
    onDrop(e) {
    },
    beforeUpload: checkFile,
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Dragger {...properties} showUploadList={false} disabled={loadingUpload}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: 'black' }} />
        </p>
        <p className="ant-upload-text">Click or drag PDF, Word, text, or CSV file to this area to upload</p>

      </Dragger>
      {filesList.map(item => (
        <div className={styles.listContainer}>
          <div className={styles.listNameContainer}>
            <FileOutlined />
            <p>{item.name}</p>
          </div>
          <DeleteOutlined onClick={() => { removeElementFromList(item) }} disabled={loadingUpload} />
        </div>
      ))}
      <div className={styles.btnContainer}>
        <Button disabled={loadingUpload} style={{ color: 'red', borderColor: 'red' }} onClick={() => { close() }}>Cancel</Button>
        <Button loading={loadingUpload} style={{ color: 'green', borderColor: 'green' }} onClick={handleFileUpload}
        >Confirm</Button>
      </div>
    </div>
  );
};

export default FileUploader;
