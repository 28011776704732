import React from 'react'
import styles from './index.module.css'
const inde = () => {
  return (
    <div className={styles.container}>
      <p>
        <p className={styles.versionHeading}>
          Version 0.0.2 Alpha
        </p>
        We are thrilled to introduce the latest alpha release, Version 0.0.02, packed with significant improvements and new features aimed at enhancing your experience with our platform. In this release, we've focused on addressing performance issues, fixing bugs, and introducing new capabilities to empower you further. Here are the highlights of this release:
        <p className={styles.itemHeading}>

          Performance Improvements
        </p>
        Improved Performance: We've made substantial under-the-hood enhancements to boost the overall performance of the platform. You can expect faster response times and smoother interactions when working with your data.
        <p className={styles.itemHeading}>

          Bug Fixes
        </p>
        Bug Fixes: Our team has been hard at work addressing various bugs and issues reported by our users. We've squashed these bugs to provide you with a more stable and reliable platform.
        <p className={styles.itemHeading}>

          Data Segment Management
        </p>
        Added Ability to Update and Delete Segments: You now have the flexibility to update and delete data segments after their creation. This feature gives you greater control over your data organization and management.
        Data Ingestion
        <br />
        Ability to Ingest CSV Files: We've expanded our data ingestion capabilities to include CSV files. You can now easily ingest and work with CSV data directly within the platform.
        Segment Rules

        Added Rules on Segments: You can now define rules on data segments to restrict the information pulled from them. This feature enhances data security and ensures that only relevant data is accessed.

        Enhanced Segment Identification: Our segment identification algorithms have been improved to provide more accurate results. This enhancement enables you to identify and work with data segments more effectively.
        <br />
        <p className={styles.versionHeading}>V.0.0.1 Alpha</p>
        We are excited to announce the alpha release of Version 0.0.1 of our platform. This release brings several new features and enhancements designed to provide more control, security, and flexibility in managing and extracting insights from your ingested data. Here are the key features and improvements in this alpha release:
        <br />
        <p className={styles.itemHeading}>

          NLP Responses
        </p>

        Ability to Get NLP Responses from Ingested Data: With this release, users can now extract valuable insights and perform natural language processing (NLP) on the data they have ingested into the platform. This feature enables you to gain deeper insights from your data and make more informed decisions.
        <p className={styles.itemHeading}>

          User Management
        </p>
        Ability to Add Users: Users can now be added to the platform, allowing for collaborative data analysis and management.
        <p className={styles.itemHeading}>

          Role Management
        </p>

        Ability to Create Roles: Admins and managers can define custom roles that match the specific needs of their organization or project.
        <br />
        Ability to Assign Roles:  Users can be assigned specific roles, ensuring that they have the appropriate level of access and permissions.
        <br />
        Ability to Modify Roles: Role definitions can be updated and modified to accommodate changing requirements.
        <br />
        <p className={styles.itemHeading}>
          Role-Level Security:
        </p>
        Enhanced security measures have been implemented to ensure that users only receive responses and access data related to their assigned roles. This feature enhances data privacy and access control.
        <br />
        <p className={styles.itemHeading}>

          Data Segmentation:
        </p>

        Ability to Create Data Segments: Users can now create data segments to organize and manage their ingested data more effectively.
        <br />
        Ability to Add Files to Segments: Files can be associated with specific data segments, improving data organization and categorization.
        <br />
        <p className={styles.itemHeading}>
          Data Ingestion:
        </p>
        Ingest Data from Single URL: Users can now easily ingest data from a single URL, simplifying the data ingestion process.
        <br />
        Ingest Sitemaps: Sitemaps can be ingested directly into the platform, making it easier to access and analyze structured data from websites.

      </p>
    </div >
  )
}

export default inde