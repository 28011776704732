import { useState, useLayoutEffect } from 'react';
import styles from './index.module.css'
import { Button, Breadcrumb, Card, Descriptions, List, Form, Select, Skeleton } from 'antd';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { getUserDetails, assignRoleToUsers, removeRoleFromUser, setUserDeactive, setUserActive } from '../../../services/userManagement.services'
import { getAllRoles } from '../../../services/roles.services';
import Swal from 'sweetalert2';

const UserDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [UserDetails, setUserDetails] = useState({})
  const [addRole, setAddRole] = useState(false)
  const [loadingAddRole, setLoadingAddRole] = useState(false)
  const [optionsRoles, setOptionsRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const [roleDataLoading, setRoleDataLoading] = useState({})

  let userAssignedRoles = []
  const items = [
    {
      key: '1',
      label: 'First Name',
      children: <p>{UserDetails.firstName}</p>,
    },
    {
      key: '1',
      label: 'Last Name',
      children: <p>{UserDetails.lastName}</p>,
    },

    {
      key: '3',
      label: 'Email',
      children: <p>{UserDetails.email}</p>,
    },

    {
      key: '5',
      label: 'Status',
      children: <p>{UserDetails.isActive ? 'Active' : 'InActive'}</p>,
    },
  ];

  const setUserStatusActive = async () => {
    const data = await setUserActive(UserDetails.userId)
    onLoad()
  }

  const setUserStatusInactive = async () => {
    const data = await setUserDeactive(UserDetails.userId)
    onLoad()
  }

  const loadRoles = async () => {
    await getAllRoles(onSucessRoles, onErrorRole)
  }

  const onFinishUpdateRole = async (values) => {
    setLoadingAddRole(true)
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    const temp = []
    UserDetails.applicationRoles.map(item => {
      temp.push(item.roleId)
    })
    values.companySegmentIds.map(item => {
      temp.push(item)
    })
    if (segmentID) {
      const data = await assignRoleToUsers({ userId: UserDetails.userId, roleIds: temp }, onErrorUpdate)
      onLoad()
      setLoadingAddRole(false)
      setAddRole(false)
    }
  };
  const onErrorRole = (status) => {
    if (status == '401') {
      navigate('/Expired')
    }
    else {
    }
  }
  const onErrorUpdate = () => {
    setLoadingAddRole(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying to update the roles of the user, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }


  const onSucessRoles = (data) => {
    const assignedRoles = userAssignedRoles
    const allSegment = data
    let tempListSegment = allSegment.filter((item1) => !userAssignedRoles.some((item2) => item1.roleId === item2.roleId));
    let temp1 = tempListSegment.map((item => {
      return { label: item.roleName, value: item.roleId }
    }))
    setOptionsRoles(temp1)
    setLoading(false);
  }

  const onLoad = async () => {
    setLoading(true)
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      const data = await getUserDetails(segmentID, onError)
      let temp = []
      data?.applicationRoles.map(file => {
        temp.push({ [file.roleId]: false })
      })
      setRoleDataLoading(temp)
      userAssignedRoles = data.applicationRoles
      setUserDetails(data)
      loadRoles()
    }
  }

  const onError = (status) => {
    if (status == '401') {
      navigate('/Expired')
    }
    else {
    }
  }


  const removeRole = async (docId) => {
    setRoleDataLoading({ ...roleDataLoading, [docId]: true })
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    await removeRoleFromUser(docId, segmentID, () => { onSucessRemove(docId) }, () => { onErrorRemove(docId) })
  }

  const onSucessRemove = async (docId) => {
    setRoleDataLoading({ ...roleDataLoading, [docId]: false })
    onLoad()
    Swal.fire({
      icon: 'success',
      text: 'The role has been scuessfully removed',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onErrorRemove = (docId) => {
    setRoleDataLoading({ ...roleDataLoading, [docId]: false })
    setLoading(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying remove the requested role, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  useLayoutEffect(() => { onLoad() }, [])

  return (
    <>
      <Card bordered title={UserDetails.firstName + ' ' + UserDetails.lastName} className={styles.cardContainer}
        style={{ paddingBottom: '10px' }}
      >
        <Breadcrumb
          items={[
            {
              title: <NavLink to="/dashboard">
                <span>Home</span>
              </NavLink>,
            },
            {
              title: <NavLink to="/usermanagement">
                <span>User Management</span>
              </NavLink>,
            },
            {
              title: 'Role Details',
            },
            {
              title: UserDetails.firstName + ' ' + UserDetails.lastName,
            },

          ]}
        />

      </Card>

      <div className={styles.container}>





        <Card bordered title="User Profile" className={styles.cardContainer}
          extra={
            UserDetails.isActive ?
              <Button style={{ color: 'red', borderColor: 'red' }} onClick={() => { setUserStatusInactive() }}>
                De-activate User
              </Button>
              :
              <Button style={{ color: 'green', borderColor: 'green' }} onClick={() => { setUserStatusActive() }}>
                Activate User
              </Button>
          }>
          {loading ? <Skeleton /> :
            <Descriptions contentStyle={{ fontSize: '46px !important' }} items={items} column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
            />}
        </Card>
        <Card
          className={styles.cardContainer}
          bordered
          title={`Assigned Roles`}
          extra={
            <div style={{ textAlign: 'center', marginRight: '30px' }}>
              <Button key="sider-menuitem-delete" onClick={() => { setAddRole(true) }}>
                + Add Role
              </Button>
            </div>
          }
        >
          {addRole &&
            <Form
              className={styles.formContainer}
              onFinish={onFinishUpdateRole}
            >
              <Form.Item
                name="companySegmentIds"
                rules={[
                  {
                    required: true,
                    message: 'Please select the segment to add to this role!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ padding: '0px' }}
                  className={styles.formInputs}
                  placeholder="Role"
                  options={optionsRoles}
                  disabled={loadingAddRole}
                />
              </Form.Item>
              <Form.Item >
                <div className={styles.btnContainer}>
                  <Button disabled={loadingAddRole} style={{ color: 'red', borderColor: 'red' }} onClick={() => {
                    setAddRole(false);
                  }}>Cancel</Button>
                  <Button loading={loadingAddRole} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                  >Confirm</Button>
                </div>
              </Form.Item>
            </Form>
          }

          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"

              dataSource={UserDetails.applicationRoles}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button key="sider-menuitem-delete" loading={roleDataLoading[item.roleId]} onClick={() => { removeRole(item.roleId) }}>
                      Unassign Role
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    title={<p >{item.roleName}</p>}
                    description={'Segments: ' + item.key}
                  />
                </List.Item>
              )}
            />}
        </Card>
        {/* <Card
          className={styles.cardContainer}
          bordered
          title="Activity"
        >
        </Card> */}
      </div>
    </>
  )
}
export default UserDetails