import React, { useState, useEffect } from 'react'
import ModalLayout from '../ModalLayout'
import { Button, Input, Form, Select, Modal } from 'antd';
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone, LockOutlined, ArrowRightOutlined } from '@ant-design/icons';
import styles from './index.module.css'
import { addSegmentTrainingData } from '../../services/segment.services';
import Swal from 'sweetalert2';

const AddSegmentModal = (props) => {
  const [segments, setSegments] = useState()

  const onFinish = (values) => {
    console.log('Values', values)
    const body = [
      {
        "companySegmentId": values.SegmentId,
        "trainingDataTypeId": 3,
        "trainingQuery": props?.chatId?.question,
        "trainingData": '',
        'trainingReferenceId': props?.chatId?.id,
      }
    ]

    addSegmentTrainingData(body, () => {
      Swal.fire({
        icon: 'error',
        text: 'We were ran into a problem while trying save your feedbaack for this response, Please try again later',
        showConfirmButton: false,
        timer: 2500
      })
    })
    props.loadData()
    props.close()
  };

  const setOptionData = () => {

    const temp = props.segmentsUser
    if (temp && temp.length > 0) {
      const data = []
      temp.map(item => {
        data.push({ value: item.companySegmentId, label: item.companySegmentName })
      })
      setSegments(data)
    }
  }

  useEffect(() => {
    setOptionData()
  }, [props])

  return (
    <Modal
      title={"Select Correct Segment"}
      centered
      on
      open={props.open}
      footer={[]}
      onCancel={() => props.close()}

    >
      <Form
        name="normal_login"
        className={styles.formContainer}
        onFinish={onFinish}
      >

        <Form.Item
          name="SegmentId"
          rules={[
            {
              required: true,
              message: 'Please select the semengt id of your new segment!',
            },
          ]}
        >
          <Select
            className={styles.formInputs}
            style={{ padding: '0px' }}
            placeholder="Select Correct Segment"
            options={segments}
          />
        </Form.Item>
        <Form.Item >
          <div className={styles.btnContainer}>
            <Button disabled={false} style={{ color: 'red', borderColor: 'red' }} onClick={() => { props.close() }}>Cancel</Button>
            <Button loading={false} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
            >Confirm</Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddSegmentModal