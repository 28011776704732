import { useLayoutEffect, useState } from "react";
import {
  UserAddOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  DatabaseOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Button, Avatar, Popover } from "antd";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import backround from "../../Assets/TRICENSION_ICON.png";
import { getCompanySetting } from "../../services/auth.services";
const { Header, Content, Footer, Sider } = Layout;

const SettingsLayout = (props) => {
  const UserObj = JSON.parse(localStorage.getItem("user"));

  const location = useLocation();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [logo, setLogo] = useState();

  const loadLogo = async () => {
    const logoUrl = await getCompanySetting(window.location.origin);
    setLogo(logoUrl);
  };
  useLayoutEffect(() => {
    loadLogo();
  }, []);

  const content = (
    <Menu
      mode="inline"
      style={{ width: "200px" }}
      selectedKeys={[location.pathname]}
    >
      <Menu.Item key="/Profile">
        <NavLink to="/Profile">
          <UserAddOutlined />
          <span>Profile</span>
        </NavLink>
      </Menu.Item>
      {UserObj.isCompanyAdmin && (
        <>
          <Menu.Item key="/dataManagement">
            <NavLink to="/dataManagement">
              <DatabaseOutlined />
              <span>Data Management</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/rolemanagement">
            <NavLink to="/rolemanagement">
              <SettingOutlined />
              <span>Role Management</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/usermanagement">
            <NavLink to="/usermanagement">
              <UserAddOutlined />
              <span>User Management</span>
            </NavLink>
          </Menu.Item>
        </>
      )}
      <Menu.Item
        key="/notifications"
        onClick={() => {
          localStorage.clear();
          navigate("/");
        }}
      >
        <LogoutOutlined />
        <span> Log Out</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout style={{ height: "100%", width: "100%" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsible={true}
        onBreakpoint={(broken) => { }}
        onCollapse={(collapsed, type) => { }}
        theme="light"
      >
        <div className="demo-logo-vertical" />
        <Menu mode="inline" selectedKeys={[location.pathname]}>
          <Menu.Item key="/dashboard">
            <div
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <MessageOutlined />
              <span>Chat</span>
            </div>
          </Menu.Item>
          <Menu.Item key="/Profile">
            <NavLink to="/Profile">
              <UserAddOutlined />
              <span>Profile</span>
            </NavLink>
          </Menu.Item>
          {UserObj.isCompanyAdmin && (
            <>
              <Menu.Item key="/dataManagement">
                <NavLink to="/dataManagement">
                  <DatabaseOutlined />
                  <span>Data Management</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/rolemanagement">
                <NavLink to="/rolemanagement">
                  <SettingOutlined />
                  <span>Role Management</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/usermanagement">
                <NavLink to="/usermanagement">
                  <UserAddOutlined />
                  <span>User Management</span>
                </NavLink>
              </Menu.Item>
            </>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: "white",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <p></p>
          <div
            style={{
              width: "200px",
              backgroundImage: `url(${logo})`,
              backgroundPosition: "center",
              height: "70px",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          >
            <p></p>
          </div>
          <Popover content={content}>
            <div
              style={{ display: "flex", alignItems: "center", padding: "10px" }}
            >
              <Avatar
                src={UserObj.profilePictureUrl}
                icon={<UserOutlined />}
                style={{ backgroundColor: "#2c3e50" }}
                size={{ xs: 24, sm: 32, md: 40, lg: 40, xl: 80 }}
              />
              <p style={{ margin: "0px 10px", fontWeight: "600" }}>
                {UserObj?.firstName + " " + UserObj?.lastName}
              </p>
            </div>
          </Popover>
        </Header>
        <Content
          style={{
            padding: "24px 16px 0",
            background:
              "linear-gradient(90deg,rgba(0, 197, 247, 1) 25%,rgba(0, 73, 126, 1) 100%)",
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: "90vh",
              maxHeight: "100%",
              height: '100%',
              overflow: "auto",
            }}
          >
            {props.children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "right",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            background:
              "linear-gradient(90deg,rgba(0, 197, 247, 1) 25%,rgba(0, 73, 126, 1) 100%)",
            color: "white",
          }}
        >
          <Button
            type="text"
            style={{ color: "white" }}
            onClick={() => {
              navigate("/ChangeLog");
            }}
          >
            Version: 0.0.2
          </Button>
          <div style={{ display: "flex", alignItems: "center" }}>
            Powered By{" "}
            <div
              style={{
                width: "25px",
                marginLeft: "5px",
                backgroundImage: `url(${backround})`,
                backgroundPosition: "center",
                height: "20px",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <p></p>
            </div>
            Tricension ©2023
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default SettingsLayout;
