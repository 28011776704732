import { useState, useEffect, useLayoutEffect } from 'react';
import styles from './index.module.css'
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Input, Form, notification } from 'antd';
import AOS from 'aos';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../Components/Button'
import 'aos/dist/aos.css';
import { resetPassword } from '../../services/auth.services'
import { getCompanySetting } from '../../services/auth.services'
import Swal from "sweetalert2";

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [loading, setloading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [logo, setLogo] = useState()

  const onFinish = async (values) => {
    setloading(true)
    await resetPassword(values.username, onSucess, onError)
  };


  const onSucess = () => {
    api['success']({
      message: 'Reset Link Sent',
      description:
        'An email has been sent to your email address which contains a password reset link',
    });
    setloading(false)

  };


  const onError = () => {
    api['error']({
      message: 'Error while generating reset Link',
      description:
        'We ran into an error while to trying to generate a password reset link, Please try again later',
    });
    setloading(false)

  };
  const loadLogo = async () => {
    const logoUrl = await getCompanySetting(window.location.origin)
    setLogo(logoUrl)
  }
  useLayoutEffect(() => {
    loadLogo()
  }, [])

  useEffect(() => { AOS.init() }, [])
  return (
    <div className={styles.container}>
      {contextHolder}

      <div className={styles.cardContainer} data-aos="fade-right"
        data-aos-duration="2000">
        <div
          style={{
            width: "200px",
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            height: "100px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <p></p>
        </div>

        <Form
          name="normal_login"
          className={styles.formContainer}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input
              className={styles.formInputs}
              placeholder="Enter your email"
              prefix={<UserOutlined className="site-form-item-icon"
                style={{ marginRight: '10px' }} />
              }

            />
          </Form.Item>

          <Form.Item>
            <Button type="text" className="login-form-button" onClick={() => { navigate('/') }} icon={<ArrowLeftOutlined />}>
              Go Back to Login
            </Button>
          </Form.Item>

          <Form.Item >
            <div className={styles.btnContainer}>
              <CustomButton
                htmlType="submit"
                text="Reset Password Link"
                customStyle={{ width: '100%' }}
                isLoading={loading}
              ></CustomButton>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ForgotPassword