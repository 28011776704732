import { useState, useEffect, useLayoutEffect } from 'react';
import styles from './index.module.css'
import { UserOutlined, EyeInvisibleOutlined, EyeTwoTone, LockOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Input, Form, notification } from 'antd';
import AOS from 'aos';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomButton from '../../../Components/Button'
import 'aos/dist/aos.css';
import { changePassword } from "../../../services/auth.services";
import { getCompanySetting } from '../../../services/auth.services'

const SetPassword = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setloading] = useState(false);
  const [passwordForm] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [logo, setLogo] = useState()

  const onFinish = async (values) => {
    setloading(true)
    const queryParams = new URLSearchParams(location.search);
    const userName = queryParams.get("userName");
    const token = queryParams.get("token");
    await changePassword({ username: userName, resetToken: token, newPassword: values.newPassword },
      onSucess, onError
    )
  };
  const onSucess = () => {

    api['success']({
      message: 'Password Changed Sucessfullt',
      description:
        'Your Password has been reset, Please Login using your new password',
    });
    setloading(false)
    navigate("/");
  };


  const onError = () => {
    api['error']({
      message: 'Error: Password Reset Failed',
      description:
        'We ran into an error whiile to trying to update your password, Please try again by requesting a new link',
    });
    setloading(false)
    navigate("/forgotpassword");
  };
  const validatePassword = (rule, value, callback) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
    if (!regex.test(value)) {
      callback('Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.');
    } else if (value.length < 8) {
      callback('Password must be at least 8 characters long.');
    } else {
      callback();
    }
  };


  const validateFieldsMatch = (rule, value, callback) => {
    const otherFieldValue = passwordForm.getFieldValue('newPassword');
    console.log(value, otherFieldValue)
    if (value !== otherFieldValue) {
      callback('Passwords do not match!');
    } else {
      callback();
    }
  };

  const loadLogo = async () => {
    const logoUrl = await getCompanySetting(window.location.origin)
    setLogo(logoUrl)
  }

  useLayoutEffect(() => {
    loadLogo()
  }, [])

  useEffect(() => { AOS.init() }, [])
  return (
    <div className={styles.container}>
      {contextHolder}

      <div className={styles.cardContainer} data-aos="fade-right"
        data-aos-duration="2000">
        <div
          style={{
            width: "200px",
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            height: "100px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        >
          <p></p>
        </div>

        <Form
          name="normal_login"
          className={styles.formContainer}
          form={passwordForm}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >

          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
              { validator: validatePassword },

            ]}
          >
            <Input.Password
              placeholder="Enter your new password"
              className={styles.formInputs}
              prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: '10px' }} />}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
              { validator: validateFieldsMatch },

            ]}
          >
            <Input.Password
              placeholder="Confirm your new password"
              className={styles.formInputs}
              prefix={<LockOutlined className="site-form-item-icon" style={{ marginRight: '10px' }} />}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="text" className="login-form-button" onClick={() => { navigate('/') }} icon={<ArrowLeftOutlined />}>
              Go Back to Login
            </Button>
          </Form.Item>

          <Form.Item >
            <div className={styles.btnContainer}>
              <CustomButton
                htmlType="submit"
                text="Confirm"
                customStyle={{ width: '100%' }}
                isLoading={loading}
              ></CustomButton>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default SetPassword