import { useLayoutEffect, useState } from 'react';
import { Button, Breadcrumb, Input, Card, Tooltip, List, Form, Skeleton, Switch, Popconfirm, Popover } from 'antd';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import {
  getSegmentDetails,
  deleteDocumentSegment,
  deleteSegment,
  updateSegmentDescription,
  getSegmentRules,
  addSegmentRules,
  removeSegmentRules,
  getSegmentTrainingData,
  addSegmentTrainingData,
  removeSegmentTrainingData,
  updateSegmentTrainingData
} from '../../../services/segment.services';
import { UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import FileUploader from '../../../Components/FileUploader';
import Swal from 'sweetalert2';
import styles from './index.module.css'


const SegmentDetails = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm();
  const [Editform] = Form.useForm();

  const [segmentData, setSegmentData] = useState({})
  const [segmentDataLoading, setSegmentDataLoading] = useState({})
  const [loading, setLoading] = useState(false)
  const [uploadFile, setUploadFile] = useState(false)
  const [showSider, setShowSider] = useState(false);
  const [addRule, setAddRule] = useState(false)
  const [addTrainingData, setAddTrainingData] = useState(false)
  const [segmentRules, setSegmentRules] = useState([]);
  const [segmentTrainingData, setSegmentTrainingData] = useState([]);
  const [segmentRulesLoading, setSegmentRulesLoading] = useState({})
  const [segmentTrainingLoading, setSegmentTrainingLoading] = useState({})
  const [loadingChangeDetails, setLoadingChangeDetails] = useState(false)
  const [loadingAddRule, setLoadingAddRule] = useState(false)
  const [loadingAddTrainingData, setLoadingTrainingData] = useState(false)
  const [loadingEditTrainingData, setLoadingEditTrainingData] = useState(false)
  const [editTraining, setEditTraining] = useState(null)

  const loadDetails = async () => {
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      setLoading(true)
      const data = await getSegmentDetails(segmentID, onErrorSegments)
      const rules = await getSegmentRules(segmentID, onErrorSegments)
      const training = await getSegmentTrainingData(segmentID, onErrorSegments)
      setSegmentRules(rules)
      setSegmentTrainingData(training)
      let temp = []
      setSegmentData(data)
      data?.files.map(file => {
        temp.push({ [file.documentId]: false })
      })
      let tempRules = []
      rules?.map(file => {
        tempRules.push({ [file.segmentRuleId]: false })
      })
      let tempTraining = []
      training?.map(file => {
        tempTraining.push({ [file.segmentTrainingDataId]: false })
      })
      setSegmentTrainingLoading(tempTraining)
      setSegmentRulesLoading(tempRules)
      setSegmentDataLoading(temp)
      form.setFieldsValue({
        ["segmentName"]: data?.companySegmentName,
        ["segmentDetails"]: data?.details?.replace('This context contains information about', '')
      });
      setLoading(false)
    }
  }

  const onErrorSegments = (status, text) => {
    if (status == '401') {
      navigate('/Expired')
    }
    else {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        text: text ? text : 'We were ran into a problem while trying delete the requested segment, Please try again later',
        showConfirmButton: false,
        timer: 2500
      })
    }
  }

  const siderSwitchToggle = () => {
    setShowSider(!showSider);
  };

  const removeDocument = async (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: true })
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    await deleteDocumentSegment(segmentID, docId, () => { onSucess(docId) }, () => { onError(docId) })
  }

  const removeRules = async (docId) => {
    setSegmentRulesLoading({ ...segmentRulesLoading, [docId]: true })
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    await removeSegmentRules(segmentID, docId, () => { onSucessRule(docId) }, () => { onErrorRule(docId) })
  }

  const onSucess = async (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: false })
    loadDetails()
    Swal.fire({
      icon: 'success',
      text: 'The document has been scuessfully deleted',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onError = (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: false })
    setLoading(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying delete the requested document, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onSucessRule = async (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: false })
    loadDetails()
    Swal.fire({
      icon: 'success',
      text: 'The rule has been scuessfully deleted',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onErrorRule = (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: false })
    setLoading(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying delete the requested rule, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const removeTrainingData = async (docId) => {
    setSegmentTrainingLoading({ ...segmentTrainingLoading, [docId]: true })
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    await removeSegmentTrainingData(segmentID, docId, () => { onSucessTrainginRemove(docId) }, () => { onErrorTrainingRemoce(docId) })
  }

  const onSucessTrainginRemove = async (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: false })
    loadDetails()
    Swal.fire({
      icon: 'success',
      text: 'The rule has been scuessfully deleted',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onErrorTrainingRemoce = (docId) => {
    setSegmentDataLoading({ ...segmentDataLoading, [docId]: false })
    setLoading(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying delete the requested rule, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const updateTrainingData = async (values) => {
    setLoadingEditTrainingData(true)
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    await updateSegmentTrainingData(editTraining, {
      "companySegmentId": segmentID,
      "trainingDataTypeId": 1,
      ...values
    }, () => { onSucessTrainginUpdate() }, () => { onErrorTrainingUpdate() })
  }

  const onSucessTrainginUpdate = async () => {
    setLoadingEditTrainingData(false)
    setEditTraining(null)
    loadDetails()
    Swal.fire({
      icon: 'success',
      text: 'The training data has been scuessfully updated',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onErrorTrainingUpdate = () => {
    setSegmentDataLoading(false)
    setLoading(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying update the requested training data, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const DeleteSegment = async () => {
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      setLoading(true)
      const data = await deleteSegment(segmentID, () => {
        Swal.fire({
          icon: 'success',
          text: 'The segment has been scuessfully deleted',
          showConfirmButton: false,
          timer: 2500
        })
        navigate('/dataManagement')
      }, (status, text) => {
        onErrorSegments(status, text)
      })
    }
  }

  const onFinishUpdateDetals = async (values) => {
    setLoadingChangeDetails(true)
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      const data = await updateSegmentDescription(segmentID, values, onErrorUpdate)
      loadDetails()
      setLoadingChangeDetails(false)
      setShowSider(false)
    }
  };

  const onErrorUpdate = () => {
    setLoadingChangeDetails(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying to update youre segment, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onFinishAddRule = async (values) => {
    setLoadingAddRule(true)
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      const data = await addSegmentRules([{ companySegmentId: segmentID, ...values }], onErrorAdd)
      loadDetails()
      setLoadingAddRule(false)
      setAddRule(false)
    }
  };

  const onErrorAdd = () => {
    setLoadingAddRule(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying to add youre segment rule, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }

  const onFinishAddTrainingData = async (values) => {
    debugger
    setLoadingTrainingData(true)
    const queryParams = new URLSearchParams(location.search);
    const segmentID = queryParams.get("id");
    if (segmentID) {
      await addSegmentTrainingData([{ companySegmentId: segmentID, trainingDataTypeId: 2, trainingReferenceId: null, ...values }], onErrorAddTraining)
      loadDetails()
      setLoadingTrainingData(false)
      setAddTrainingData(false)
    }
  };

  const onErrorAddTraining = () => {
    setLoadingAddRule(false)
    Swal.fire({
      icon: 'error',
      text: 'We were ran into a problem while trying to add youre training data, Please try again later',
      showConfirmButton: false,
      timer: 2500
    })
  }


  const confirm = (e) => {
    DeleteSegment()
  };

  const cancel = (e) => {
  };
  useLayoutEffect(() => {
    loadDetails()
  }, [])

  return (
    <>
      <Card bordered title={segmentData?.companySegmentName} className={styles.cardContainer}
        style={{ paddingBottom: '10px' }}

      >
        <Breadcrumb
          items={[
            {
              title: <NavLink to="/dashboard">
                <span>Home</span>
              </NavLink>,
            },
            {
              title: <NavLink to="/dataManagement">
                <span>Data Management</span>
              </NavLink>
              ,
            },

            {
              title: 'Segment Details',
            },
            {
              title: "Tricension",
            },
          ]}
        />

      </Card>

      <div className={styles.container}>
        {/* Details and Description */}
        <Card bordered title="Segment Details"
          className={styles.cardContainer}
          extra={[
            <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', alignItems: 'end' }}>
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this segment?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button key="sider-menuitem-delete" danger disabled={loading}>
                  Delete Segment
                </Button>  </Popconfirm>
              <div className={styles.switchDiv}>
                <span >Enable Edit Mode</span>
                <Switch
                  disabled={loadingChangeDetails}
                  checkedChildren="Edit"
                  unCheckedChildren="View"
                  checked={showSider}
                  onChange={siderSwitchToggle}
                />
              </div>
            </div>
          ]}
        >
          <Form
            form={form}
            className={styles.formContainer}
            initialValues={{
              ["segmentName"]: segmentData?.companySegmentName,
              ["segmentDetails"]: segmentData?.details?.replace('This context contains information about', '')
            }}
            onFinish={onFinishUpdateDetals}
          >
            <Form.Item
              name="segmentName"
              rules={[
                {
                  required: true,
                  message: 'Please input the name of your segment!',
                },
              ]}
            >
              <Input
                disabled={!showSider || loadingChangeDetails}
                className={styles.formInputs}
                placeholder="Enter Segment Name"
                prefix={<UserOutlined className="site-form-item-icon"
                  style={{ marginRight: '10px' }} />
                }
              />
            </Form.Item>
            <Form.Item
              name="segmentDetails"
              rules={[
                {
                  required: true,
                  message: 'Please input the description of your segment in the form of keyword!',
                },
              ]}
            >
              <Input
                disabled={!showSider || loadingChangeDetails}
                className={styles.formInputs}
                placeholder="Enter Segment Description"
                prefix={<UserOutlined className="site-form-item-icon"
                  style={{ marginRight: '10px' }} />
                }
              />
            </Form.Item>

            <Form.Item >
              {showSider &&
                <div className={styles.btnContainer}>
                  <Button disabled={loadingChangeDetails} style={{ color: 'red', borderColor: 'red' }} onClick={() => {
                    setShowSider(false); form.setFieldsValue({
                      segmentName: segmentData?.companySegmentName,
                      segmentDetails: segmentData?.details?.replace('This context contains information about', '')
                    });
                  }}>Cancel</Button>
                  <Button loading={loadingChangeDetails} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                  >Confirm</Button>
                </div>
              }

            </Form.Item>
          </Form>
        </Card>
        {/* Files */}
        <Card
          className={styles.cardContainer}
          bordered
          title={`Files`}
          extra={[<Button key="sider-menuitem-delete" style={{ marginRight: '30px' }}
            onClick={() => { setUploadFile(true) }}>
            Add File
          </Button>]}
        >
          {uploadFile && <FileUploader close={() => { loadDetails(); setUploadFile(false) }} />}
          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segmentData?.files?.reverse()}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button key="sider-menuitem-delete" loading={segmentDataLoading[item.documentId] || item.isDeleted === 1}
                      disabled={item.isProcessed === 3 ? false : item.isProcessed === 2 ? false : 'Processing'}
                      onClick={() => { removeDocument(item.documentId) }}>
                      {item.isDeleted === 1 ? 'Removing File' : 'Remove File'}
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Tooltip placement="leftTop" title={'Click Here to download this file'} >
                        <p style={{ cursor: 'pointer' }} onClick={() => { window.open(item.referenceId) }}>{item.title}</p>
                      </Tooltip>
                    }
                    description={
                      <p style={{ color: `${item.isProcessed === 3 ? 'red' : item.isProcessed === 2 ? 'green' : 'black'}` }}>{`Status: ${item.isProcessed === 3 ? 'Failed To Process Please Remove and add again' : item.isProcessed === 2 ? 'Processed' : 'Processing'}`}</p>
                    }
                  />
                </List.Item>
              )}
            />
          }
        </Card>
        {/* Identificatio Rules */}
        <Card
          className={styles.cardContainer}
          bordered
          title={<div style={{ display: 'flex', flexDirection: 'row' }}><p style={{ marginRight: '10px' }}>Segment Identification Training Data</p>  <Popover title={<p>This feature allows you to add a set of question and answers <br /> This will enhance the AI Engines capabilits to direct questions to this segment when using the chat feature</p>} className={styles.infoPopOver}>
            <InfoCircleOutlined />
          </Popover></div>}
          extra={[
            <Button key="sider-menuitem-delete" style={{ marginRight: '30px' }}
              onClick={() => { setAddTrainingData(true) }}>
              Add Training Data
            </Button>]}

        >
          {addTrainingData ? <Form
            className={styles.formContainer}
            onFinish={onFinishAddTrainingData}
          >
            <Form.Item
              name="trainingQuery"
              rules={[
                {
                  required: true,
                  message: 'Please input the question',
                },
              ]}
            >
              <Input
                disabled={loadingAddTrainingData}
                className={styles.formInputs}
                placeholder="Enter Training Question"
                prefix={<UserOutlined className="site-form-item-icon"
                  style={{ marginRight: '10px' }} />
                }
              />
            </Form.Item>
            <Form.Item
              name="trainingData"
              rules={[
                {
                  required: true,
                  message: 'Please input the answer of your training question',
                },
              ]}
            >
              <Input
                disabled={loadingAddTrainingData}
                className={styles.formInputs}
                placeholder="Enter Training Answer"
                prefix={<UserOutlined className="site-form-item-icon"
                  style={{ marginRight: '10px' }} />
                }
              />
            </Form.Item>

            <Form.Item >
              <div className={styles.btnContainer}>
                <Button disabled={loadingAddTrainingData} style={{ color: 'red', borderColor: 'red' }} onClick={() => {
                  setAddTrainingData(false);
                }}>Cancel</Button>
                <Button loading={loadingAddTrainingData} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                >Confirm</Button>
              </div>
            </Form.Item>
          </Form> : ''}
          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segmentTrainingData}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <>
                      {(editTraining !== item.segmentTrainingDataId && !item.isTrained) &&
                        <>

                          <Button key="sider-menuitem-delete" loading={segmentTrainingLoading[item.segmentTrainingDataId]} onClick={() => {
                            Editform.setFieldsValue({
                              ["trainingQuery"]: item.trainingQuery,
                              ["trainingData"]: item.trainingData
                            });
                            setEditTraining(item.segmentTrainingDataId)
                          }}>
                            Edit
                          </Button>
                          <Button key="sider-menuitem-delete" loading={segmentTrainingLoading[item.segmentTrainingDataId]} onClick={() => { removeTrainingData(item.segmentTrainingDataId) }}>
                            Remove
                          </Button>
                        </>}
                    </>
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <div >
                        {editTraining == item.segmentTrainingDataId ? <Form
                          form={Editform}
                          className={styles.formContainer}
                          onFinish={updateTrainingData}
                        >
                          <Form.Item
                            name="trainingQuery"
                            rules={[
                              {
                                required: true,
                                message: 'Please input the training data question of your segment!',
                              },
                            ]}
                          >
                            <Input
                              disabled={loadingEditTrainingData}
                              className={styles.formInputs}
                              placeholder="Enter Training Data Question"
                              prefix={<UserOutlined className="site-form-item-icon"
                                style={{ marginRight: '10px' }} />
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name="trainingData"
                            rules={[
                              {
                                required: true,
                                message: 'Please input the answer of your training question',
                              },
                            ]}
                          >
                            <Input
                              disabled={loadingEditTrainingData}
                              className={styles.formInputs}
                              placeholder="Enter Answer of Training Question"
                              prefix={<UserOutlined className="site-form-item-icon"
                                style={{ marginRight: '10px' }} />
                              }
                            />
                          </Form.Item>

                          <Form.Item >
                            <div className={styles.btnContainer}>
                              <Button disabled={loadingEditTrainingData} style={{ color: 'red', borderColor: 'red' }} onClick={() => {
                                setEditTraining(null);
                              }}>Cancel</Button>
                              <Button loading={loadingEditTrainingData} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                              >Confirm</Button>
                            </div>


                          </Form.Item>
                        </Form>
                          : <p>{item.trainingQuery}</p>}
                      </div>

                    }
                    description={
                      <>
                        <p >{editTraining !== item.segmentTrainingDataId && 'Description: ' + item.trainingData}</p>
                        {item.isTrained && <p style={{ color: 'green' }}>Training data has been integrated into the AI Modal Sucessfully</p>
                        }
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          }
        </Card>
        {/* Segment Rules */}
        <Card
          className={styles.cardContainer}
          bordered
          title={<div style={{ display: 'flex', flexDirection: 'row' }}><p style={{ marginRight: '10px' }}>Segment Rules</p>  <Popover title={
            <p>This feature allows you to add a set of instructions for the AI model
              <br />
              This will enhance the AI Engines capabilits to return the type of information you are are looking for in the chat feature, when asking questions from this segment</p>}
            className={styles.infoPopOver}>
            <InfoCircleOutlined />
          </Popover></div>}
          extra={[<Button key="sider-menuitem-delete" style={{ marginRight: '30px' }}
            onClick={() => { setAddRule(true) }}>
            Add Rule
          </Button>]}

        >
          {addRule &&
            <Form
              className={styles.formContainer}
              onFinish={onFinishAddRule}
            >
              <Form.Item
                name="ruleName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the name of your new rule!',
                  },
                ]}
              >
                <Input
                  disabled={loadingAddRule}
                  className={styles.formInputs}
                  placeholder="Enter  Rule Name"
                  prefix={<UserOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>
              <Form.Item
                name="ruleData"
                rules={[
                  {
                    required: true,
                    message: 'Please input the description of your rule!',
                  },
                ]}
              >
                <Input
                  disabled={loadingAddRule}
                  className={styles.formInputs}
                  placeholder="Enter Rule Description"
                  prefix={<UserOutlined className="site-form-item-icon"
                    style={{ marginRight: '10px' }} />
                  }
                />
              </Form.Item>

              <Form.Item >
                <div className={styles.btnContainer}>
                  <Button disabled={loadingAddRule} style={{ color: 'red', borderColor: 'red' }} onClick={() => {
                    setAddRule(false);
                  }}>Cancel</Button>
                  <Button loading={loadingAddRule} style={{ color: 'green', borderColor: 'green' }} htmlType="submit"
                  >Confirm</Button>
                </div>
              </Form.Item>
            </Form>}
          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segmentRules}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button key="sider-menuitem-delete" loading={segmentRulesLoading[item.segmentRuleId]} onClick={() => { removeRules(item.segmentRuleId) }}>
                      Remove Rule
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <p >{item.ruleName}</p>

                    }
                    description={
                      <p >{'Description: ' + item.ruleData}</p>
                    }
                  />
                </List.Item>
              )}
            />
          }
        </Card>
        {/* Roles Contianing Segment */}
        <Card
          className={styles.cardContainer}
          style={{ paddingBottom: '80px' }}
          bordered
          title={`Roles Containing this Segment`}
        // extra={[<Button key="sider-menuitem-delete" style={{ marginRight: '30px' }}>
        //   Add New Role
        // </Button>]}
        >
          {loading ? <Skeleton /> :
            <List
              bordered
              itemLayout="horizontal"
              dataSource={segmentData?.roles}
              renderItem={(item) => (
                <List.Item
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                  actions={[
                    <Button key="sider-menuitem-delete" onClick={() => {
                      navigate(`/rolemanagement/RoleDetails?id=${item.roleId}`)
                    }}>
                      View Details
                    </Button>
                  ]}
                >
                  <List.Item.Meta
                    title={<p>{item.roleName}</p>}
                    description={<p>{'Allowed Users Count:' + item.userCount} <br /> {item.sitemapUrl}</p>}
                  />

                </List.Item>
              )}
            />}
        </Card>
      </div>
    </>
  )
}

export default SegmentDetails